.contact{
  background-color: #f3f3f8;
}

.contact-wrapper{
  padding-top: 80px;
  @include media('<=tablet'){
    padding-top: 45px;
  }

  .subtitle{
    font-size: 28px;
    color: $darkBlue;
    margin: 0 0 50px;
    @include media('<=tablet'){
      font-size: 24px;
      margin-bottom: 30px;
    }
  }

  form{
    display: flex;
    justify-content: space-between;
    margin-bottom: 165px;
    @include media('<=tablet'){
      display: block;
      margin-bottom: 60px;
    }

    .btn{
      margin-top: 60px;
      @include media('<=tablet'){
        max-width: 100%;
        margin-top: 20px;
      }
    }
  }

  textarea{
    min-height: calc(100% - 46px);
    @include media('<=tablet'){
      min-height: 335px;
    }
  }

  .left-side{
    width: 100%;
    max-width: 380px;
    @include media('<=tablet'){
      max-width: 100%;
    }
  }

  .right-side{
    width: 100%;
    max-width: 460px;
    margin-left: 100px;
    @include media('<=tablet'){
      max-width: 100%;
      margin: 20px 0 0;
    }
  }
}
.map {
  width: 100%;
  height: 700px;
  &-inner {
    width: 100%;
    height: 700px;
  }
  &-info {
    height: 380px;
    width: 380px;
    background: #39aaff;
    padding: 30px;
    z-index: 100;
    top: -550px;
    position: absolute;
    @include media('<=tablet'){
      width: 80%;
      left: 50%;
      transform: translateX(-50%);
      height: auto;
    }
    h2 {
      font-size: 28px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.14;
      letter-spacing: 0.3px;
      color: #ffffff;
      margin: 0;
      margin-bottom: 40px;
      @include media('<=tablet'){
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
    p {
      font-size: 20px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.6;
      letter-spacing: 0.3px;
      color: #ffffff;
      margin: 0;
      @include media('<=tablet'){
        font-size: 14px;
      }
      br {
        display: none;
      }
    }
    a {
      color: #ffffff;
      margin-top: 20px;
      display: block;
      text-decoration: none;
      @include media('<=tablet'){
        margin-top: 10px;
      }
    }
    h6 {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.57;
      letter-spacing: 0.3px;
      color: #ffffff;
      margin: 0;
      margin-top: 20px;
      display: block;
      @include media('<=tablet'){
        font-size: 12px;
      }
    }
  }
}