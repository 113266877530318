.team-item{
  position: relative;
  max-width: 220px;
  text-decoration: none;
  color: inherit;
  z-index: 4;
  &.js-team-member, &.js-match-maker {
    &:nth-child(n+5) {
      display: none;
    }
  }
  &:hover{
    z-index: 1000;
    .person{
      border-color: $lightBlue;
    }

    .hidden-text{
      opacity: 1;
      visibility: visible;
      transform: scaleY(1)
    }

    h4, h5, h6{
      color: $white;
    }
    svg {
      path {
        fill:$white;
      }
    }
  }
  .person{
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 5px solid #d7d7de;
    margin: 0 auto;
    transition: border-color 200ms;
    backface-visibility: hidden;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #fff;
      border-radius: 100%;
    }
    .img-wrapper{
      position: absolute;
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 10px;
      overflow: hidden;
      border-radius: 50%;
    }

    img{
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .description{
    padding: 30px 15px 0;
  }

  h4{
    font-size: 16px;
    font-weight: 600;
    line-height: 1.33;
    letter-spacing: 0.3px;
    margin: 0;
    color: $darkGray;
    text-align: center;
    transition: color 200ms;
    backface-visibility: hidden;

    @include media('<=tablet'){
      font-size: 14px;
    }
  }

  h5{
    font-size: 12px;
    font-weight: 600;
    line-height: 1.35;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $darkGray;
    text-align: center;
    margin: 10px 0 0;
    transition: color 200ms;
    backface-visibility: hidden;

    @include media('<=tablet'){
      font-size: 10px;
    }
  }

  .social{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0 25px;
    margin: 0;
    border-top: 1px solid $white;
    text-align: center;
    list-style-type: none;

    li{
      & + li{
        margin-left: 25px;
      }
    }
  }

  .hidden-text{
    position: absolute;
    top: 75px;
    left: 0;
    right: 0;
    opacity: 0;
    z-index: -1;
    padding: 185px 15px 0;
    visibility: hidden;
    transform: scaleY(0);
    transition: opacity 200ms, visibility 200ms;
    backface-visibility: hidden;
    background-color: $lightBlue;

    @include media('<=tablet'){
      left: -20px;
      right: -20px;
      padding: 185px 25px 0;
    }
  }

  .description{
    .hidden-text{
      p{
        font-size: 14px;
        line-height: 1.43;
        letter-spacing: 0.8px;
        color: $white;
        margin: 0 0 40px;
      }
    }
  }
}
.match-maker {
  h5{
    text-transform: initial;
  }
  h6{
    font-size: 12px;
    font-weight: 600;
    line-height: 1.35;
    letter-spacing: 1px;
    color: #a6a6ad;
    text-align: center;
    margin: 10px 0 0;
    transition: color 200ms;
    backface-visibility: hidden;
  }
  svg {
    vertical-align: top;
  }
  .hidden-text {
    padding: 195px 15px 0;
  }
}

.hidden {
	display: none !important;
}