.main-navigation{
  position: relative;
  top: 0;
  z-index: 100000;
  background-color: $white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  padding: 20px 0 0;
  margin: 0;
  @include media('<=tablet'){
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 14px 0;
    z-index: 100000;
  }
  .container{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .logo{
    margin-bottom: 15px;
    @include media('<=tablet'){
      margin-bottom: 0;
    }
    img{
      @include media('<=tablet'){
        max-height: 45px;
      }
    }
  }

  .dropdown-opener{
    position: absolute;
    top: 17px;
    right: 0;
    border: 0;
    outline: 0;
    padding: 0;
    background-color: transparent;
    color: #979797;
    font-size: 28px;
    transition: color 200ms, transform 200ms;
    backface-visibility: hidden;
    transform-origin: 50%;
    width: 28px;
    height: 28px;
    line-height: 28px;
    z-index: 10000000000000000;
    @include media('>tablet'){
      display: none;
    }
    &:focus{
      color: $darkBlue;
      transform: rotate(45deg);
      & + .dropdown{
        display: block;
      }
    }
    &--active {
      color: $darkBlue;
      transform: rotate(45deg);
    }
  }

  .main-ul{
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    @include media('<=tablet'){
      position: fixed;
      top: 79px;
      left: 0;
      right: 0;
      display: block;
      background-color: $white;
      border-top: 1px solid $lightGray;
      padding: 0 30px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
      opacity: 0;
      transform: scaleY(0);
      transform-origin: 0 0;
      transition: transform 200ms, opacity 200ms, top 500ms;
    }

    li:not(.inner-li){
      position: relative;
      padding: 0 5px 20px;
      display: block;
      @include media('<=tablet'){
        padding: 20px 0;
      }
      & + li{
        margin-left: 15px;
        @include media('<=tablet'){
          margin-left: 0;
          border-top: 1px solid #979797;
        }
      }
      &:hover{
        .dropdown{
          opacity: 1;
          visibility: visible;
        }
      }
      a:not(.inner-a){
        position: relative;
        color: #76797a;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.3px;
        text-decoration: none;
        transition: color 200ms;
        transform: translateZ(0);
        padding: 0 5px 20px;
        border-bottom: 2px solid transparent;
        text-transform: lowercase;
        @include media('<=tablet'){
          padding: 20px 10px;
        }

        @include media('>tablet'){
          &:after{
            position: absolute;
            content: "";
            left: 0;
            right: 0;
            bottom: 0px;
            height: 4px;
            opacity: 0;
            background-color: $yellow;
            transform: translateZ(0);
            transition: opacity 200ms;
          }
        }
        &:hover, &.current_page_item {
          color: #39393b;
          @include media('<=tablet'){
            color: $darkBlue;
          }
          &:after{
            opacity: 1;
          }
        }
      }
    }
    .dropdown{
      position: absolute;
      top: 40px;
      min-width: 190px;
      left: -95px;
      padding: 0;
      margin: 0;
      padding: 0 30px;
      border: 1px solid #ebebf0;
      background-color: $white;
      font-size: 0;
      opacity: 0;
      visibility: hidden;
      transform: translate3d(47.5px, 0,0);
      transition: opacity 200ms, visibility 200ms;
      @include media('<=tablet'){
        display: none;
        position: relative;
        opacity: 1;
        visibility: visible;
        background-color: #fafaff;
        -webkit-box-shadow: inset 0 2px 4px 0 rgba(0,0,0,.15);
        box-shadow: inset 0 2px 4px 0 rgba(0,0,0,.15);
        width: calc(100% + 70px);
        left: -80px;
        top: 20px;
        padding: 10px 40px 0;
        border: 0 !important;
        margin-bottom: -1px;
        z-index: 10;
      }
      &--active {
        display: block;
      }
      &:before, &:after{
        position: absolute;
        content: "";
        left: -1px;
        right: -1px;
        height: 18px;
        border-left: 4px solid $yellow;
        border-right: 4px solid $yellow;
        @include media('<=tablet'){
          display: none;
        }
      }
      &:before{
        position: absolute;
        content: "";
        left: -1px;
        right: -1px;
        height: 18px;
        border-left: 4px solid $yellow;
        border-right: 4px solid $yellow;
        @include media('<=tablet'){
          display: block;
          top: -66px !important;
          left: 2px;
          height: auto;
          bottom: 0;
          width: 4px;
          border: 0 !important;
          background: $yellow;
        }
      }

      &:before{
        top: -1px;
        border-top: 4px solid $yellow;
      }

      &:after{
        bottom: -1px;
        border-bottom: 4px solid $yellow;
      }

      li{
        position: relative;
        display: block;
        text-align: center;
        min-height: 50px;
        @include media('<=tablet'){
          text-align: left;
        }
        &:before{
          content: "";
          display: inline-block;
          vertical-align: middle;
          min-height: 50px;
        }
        &:not(:last-of-type){
          border-bottom: 1px solid #ebebf0;
        }
        &:last-of-type{
          padding-bottom: 2px;
        }

        a{
          display: inline-block;
          vertical-align: middle;
          font-size: 14px;
          letter-spacing: 0.3px;
          line-height: 1.14;
          padding: 8px 0;
          font-weight: 400;
          text-decoration: none;
          color: $darkBlue;
          transition: color 200ms;
          backface-visibility: hidden;
          @include media('<=tablet'){
            font-size: 16px;
            font-weight: normal;
            letter-spacing: 0.3px;
            color: #00254f;
          }

          &:before{
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
          &:hover{
            color: $lightBlue;
          }
        }
      }
    }
  }
}

#navigation-opener{
  display: none;
  &:checked{
    & + .navigation-opener{
      & + .main-ul{
        transform: scaleY(1);
        opacity: 1;
      }
    }
  }
}
.navigation-opener{
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  width: 38px;
  height: 24px;
  margin: 0;
  font-size: 0;
  border: solid #a6a6ad;
  border-width: 2px 0;
  @include media('>tablet'){
    display: none;
  }
  &:before{
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    right: 0;
    border-bottom: 2px solid #a6a6ad;
    transform: translateY(-50%);
  }
}

.current_page_item {
  a {
    color: #39393b;
    @include media('<=tablet'){
      color: $darkBlue;
    }
    &:after{
      opacity: 1;
    }
  }
}

.js-SiteHeader {
  position: fixed;
  left: 0;
  right: 0;
  transition: 300ms;
  &--down, &--marginer {
    top: 0;
  }
  &--up {
    top: -120px;
    .dropdown {
      visibility: hidden !important;
      opacity: 0 !important;
    }
    @include media('<=tablet'){
      .main-ul {
        top: -1000px;
      }
      .dropdown {
        visibility: visible !important;
        opacity: 1 !important;
      }
    }
  }
}