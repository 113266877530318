.services-for-startups{
  .under-header-items{
    display: flex;
    justify-content: space-between;
    padding: 50px 0 75px;
    @include media('<=tablet'){
      display: block;
    }
    .box-item{
      max-width: 460px;
      @include media('<=tablet'){
        max-width: 100%;
        margin-bottom: 20px;
      }
      /*&:last-of-type{
        &:before{
          transform: translateX(100%);
        }

        &:hover{
          &:before{
            transform: translateX(0);
          }
        }
      }*/
    }
  }

  .criteria{
    margin: 150px 0 185px;
    @include media('<=tablet'){
      margin-bottom: 100px;
    }
    p{
      max-width: 430px;
      margin: 25px 0 0;
      font-size: 20px;
      font-weight: 300;
      line-height: 1.6;
      letter-spacing: 0.3px;
      color: $darkGray;
      @include media('<=tablet'){
        font-size: 16px;
        line-height: 1.38;
        margin: 15px 0 0;
        &:first-of-type {
          margin-top: 40px;
        }
      }
    }

    .btn{
      margin: 45px 0;
    }

    .img-right{
      position: absolute;
      top: -225px;
      right: 0;
      max-width: 410px;
      @include media('<=tablet'){
        right: auto;
        position: relative;
        top: -150px;
        margin-bottom: -130px;
        max-width: 100%;
      }
    }

    hr{
      width: calc(100% + 750px);
      @include media('<=tablet'){
        width: 80%;
      }
    }
  }

  .sales-acceleration{
    margin-bottom: 185px;
    position: relative;
    @include media('<=tablet'){
      margin-bottom: 0;
    }
    .container.bordered{
      max-width: 460px;
      margin-left: auto;
      margin-right: 0;

      @include media('<=tablet'){
        padding: 0;
        position: static;
      }
    }

    .img-left{
      position: absolute;
      left: -140px;
      bottom: 80px;
      max-width: 450px;
      @include media('<=tablet'){
        display: none;
      }
    }
    .img-mobile {
      display: none;
      max-width: 100%;
      margin-top: 100px;
      margin-bottom: -200px;
      @include media('<=tablet'){
        display: block;
        margin-bottom: 50px;
      }
    }

    .background-wrapper{
      &:before{
        mix-blend-mode: initial !important;
        background-image: url('../../public/images/BG_startups_sales_acceleration.jpg');
        margin-left: calc(100vw - 2000px) !important;
        z-index: -1 !important;
      }
      &:after {
        display: none !important;
      }
    }

    @include media('<=tablet'){
      hr.reversed {
        left: 0;
        width: calc(100% - 45px);
        &:before {
          left: auto;
          right: 0;
          transform: translate(100%, -50%);
        }
        &:after {
          left: auto;
          right: -6px;
          transform: translate(100%, -50%);
        }
      }
    }
  }

  .match-making{
    position: relative;
    @include media('<=tablet'){
     background: transparent;
    }
    hr {
      /*left: -60% !important;*/
      width: calc(100% + 750px);
    }
    .img-right {
      position: absolute;
      top: -205px;
      right: 0;
      max-width: 800px;
      @include media('<=1580px'){
        max-width: 600px;
        top: -65px;
      }
      @include media('<=1010px'){
        max-width: 450px;
        top: 0px;
      }
      @include media('<=tablet'){
        max-width: 100%;
        position: relative;
        top: auto;
      }
    }
    .background-wrapper{
      &:before{
        background-image: url('../../public/images/BG_startups_match_making.jpg');
        z-index: -1 !important;
        margin-left: calc(100vw - 1500px) !important;
      }
      &:after {
        display: none;
      }
    }
  }

  .sales-acceleration {
    .container.bordered{
      padding-bottom: 130px;

      @include media('<=tablet'){
        padding-bottom: 0;
      }
    }

    hr + p{
      color: $darkGray;
    }

    p{
      font-size: 20px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.56;
      letter-spacing: 0.4px;
      margin: 40px 0 0;
      color: $white;
      max-width: 460px;
      @include media('<=tablet'){
        color: $darkGray;
        font-size: 16px;
        line-height: 1.38;
        margin: 15px 0 0;
        &:first-of-type {
          margin-top: 40px;
        }
      }
    }
    a {
      text-decoration: none;
      color: $lightBlue;
    }

    .background-wrapper{
      position: relative;
      padding: 5px 0 70px;
      margin-top: 30px;
      p {
        @include media('<=tablet'){
          &:first-of-type {
            margin-top: 15px;
          }
        }  
      }
      &:before, &:after{
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        @include fullWidth();
      }

      &:before{
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        mix-blend-mode: multiply;
      }

      &:after{
        background-color: $blue;
        z-index: -1;
      }
    }
  }
  .match-making{
    .container.bordered{
      padding-bottom: 130px;

      @include media('<=tablet'){
        padding-bottom: 0;
      }
    }

    hr + p{
      color: $darkGray;
    }

    p{
      font-size: 20px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.56;
      letter-spacing: 0.4px;
      margin: 40px 0 0;
      color: $white;
      max-width: 460px;
      @include media('<=tablet'){
        color: $darkGray;
        font-size: 16px;
        line-height: 1.38;
        margin: 15px 0 0;
        &:first-of-type {
          margin-top: 40px;
        }
      }
    }

    .background-wrapper{
      position: relative;
      padding: 5px 0 70px;
      margin-top: 30px;
      p {
        @include media('<=tablet'){
          &:first-of-type {
            margin-top: 15px;
          }
        }  
      }
      &:before, &:after{
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        @include fullWidth();
      }

      &:before{
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        mix-blend-mode: multiply;
      }

      &:after{
        background-color: $blue;
        z-index: -1;
      }
    }
  }

  .process{
    position: relative;
    z-index: 2;

    &:before{
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      mix-blend-mode: multiply;
      background-image: url('../../public/images/BG_startups_partnership_process.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
    .subtitle{
      padding: 25px 20px 20px;
      border: 5px solid $white;
      border-top: 0;
      max-width: 230px;
      color: $white;
      margin: 0;
      @include media('<=tablet'){
        border: 0;
        position: relative;
        max-width: 100%;
        padding-left: 0;
        &:before {
          content: '';
          position: absolute;
          left: -20px;
          right: -20px;
          bottom: 0;
          height: 5px;
          background: $white;
        }
      }
    }
  }

  .process-items{
    position: relative;
    margin: 0;
    padding: 60px 0 80px 90px;
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    text-align: center;
    @include media('<=tablet'){
     display: block;
     padding-left: 0;
     text-align: left;
    }
    &:before{
      position: absolute;
      content: "";
      top: 117px;
      left: 35px;
      right: -50vw;
      border-bottom: 2px solid $white;
      @include media('<=tablet'){
        display: none;
      }
    }

    &:after{
      position: absolute;
      content: "";
      top: 0;
      left: 35px;
      height: 117px;
      border-left: 2px solid $white;
      @include media('<=tablet'){
        bottom: 0;
        height: auto;
      }
    }

    a{
      text-decoration: none;
    }

    li{
      position: relative;
      max-width: 140px;
      width: 100%;
      z-index: 2;
      &.is-active, &:hover{
        span{
          &:not(.small-circle){
            color: $lightBlue;
          }
          &.small-circle{
            &:after{
              opacity: 1;
            }
          }
        }
      }
      span{
        &:not(.small-circle){
          display: block;
          font-size: 20px;
          font-weight: 600;
          line-height: 1.1;
          letter-spacing: 0.3px;
          color: $white;
          transition: color 200ms;
          backface-visibility: hidden;
          @include media('<=tablet'){
            display: inline-block;
          }
        }
        &.small-circle{
          position: relative;
          margin: 27px auto 25px;

          @include media('<=tablet'){
            display: inline-block;
            margin: 0 10px;
          }
          &:before{
            background-color: $blue;
            border-color: $white;
            width: 20px;
            height: 20px;
            @include media('<=tablet'){
              top: -5px;
              left: 10px;
            }
          }

          &:after{
            opacity: 0;
            transition: opacity 200ms;
            backface-visibility: hidden;
            background-color: $white;
            @include media('<=tablet'){
              top: -5px;
              left: 10px;
            }
          }
        }
      }
      @include media('<=tablet'){
        padding: 10px 0;
        max-width: 100%;
      }
    }
  }
}

.fill-out-form{
  background-color: $lightBlue;
  .container{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @include media('<=tablet'){
      flex-wrap: wrap-reverse;
    }
  }

  .half{
    width: 50%;
    &:first-of-type{
      font-size: 0;
    }

    &:last-of-type{
      align-self: flex-start;
    }
    @include media('<=tablet'){
      width: 100%;
    }
  }

  h3{
    color: $white;
    font-size: 26px;
    font-weight: bold;
    line-height: 1.23;
    letter-spacing: 0.3px;
    margin: 45px 0;
  }

  .btn{
    max-width: 420px;
  }

  .img-wrapper{
    margin-top: -120px;
    @include media('<=tablet'){
      margin-top: 30px;
    }
  }

  img{
    width: 100%;
    max-width: 420px;
  }
}