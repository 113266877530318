
form{
  label{
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.3px;
    color: $darkGray;
    display: inline-block;
    margin-bottom: 17px;
    @include media('<=tablet'){
      display: block;
      font-size: 18px;
      margin-bottom: 5px;
    }
  }

  @include media('<=tablet'){
    br {
      display: none !important;
    }
  }
}

//input[type="text"] i input[type="email"] ne radi na nuxtu nesto pa zasad samo input

input, textarea{
  display: block;
  padding: 13px 20px 12px;
  border: 1px solid #a6a6ad;
  color: $darkGray;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.3px;
  width: 100%;
  outline: 0;
  max-height: 56px;

  &:focus{
    border-color: $lightBlue;
  }

  & + label{
    margin-top: 20px;
  }

  @include media('<=tablet'){
    margin-bottom: 15px;
  }
}

textarea{
  resize: none;
}

fieldset{
  border: 0;
  margin: 0;
  padding: 0;
}

select{
  background-color: $white;
  border: 1px solid #a6a6ad;
  color: #a6a6ad;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.3px;
  padding: 10px 20px 12px;
  width: 100%;
  max-width: 460px;
  outline: 0;
  &:focus{
    border-color: $lightBlue;
  }

  @include media('<=tablet'){
    max-width: 100%;
  }
}
.sgpb-popup-close-button-2 {
  top: -30px !important;
}
#sgpb-popup-dialog-main-div {
  border: 0 !important;
}
.wmpci-popup-body {
  .btn.btn-medium {
    margin: 0 auto;
  }
  .form-control {
    border: 0 !important;
    max-width: 70% !important;
    margin: 0 auto !important;
  }
  .wmpci-popup-close {
    background: rgba(0, 0, 0, 0) url("https://www.match-maker.ventures/wp-content/uploads/2018/12/close-white.png") no-repeat scroll 0 0 !important;
    right: 0px !important;
    top: 10px !important;
  }
  h1 {
    strong {
      font-weight: 700 !important;
    }
    font-weight: 400 !important;
  }
}