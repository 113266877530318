@mixin setBorder($color){
  background-color: $color;
  &.bordered{
    &:after{
      border-color: $color;
    }

    &:before{
      background-color: $color;
    }

    h3{
      color: $color;
    }
  }

  .wrapper{
    border-color: $color;
  }
}

.box-item{
  position: relative;
  display: block;
  max-width: 300px;
  font-size: 0;
  text-decoration: none;
  transform: translateZ(0);
  @include media('<=tablet'){
    max-width: 100%;
  }

  &:not(.no-link){
    &:hover{
      p{
        opacity: 0;
        visibility: hidden;
      }

      button{
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &.light-blue{
    @include setBorder($lightBlue);
  }

  &.dark-blue{
    @include setBorder($darkBlue);
  }

  &.blue{
    @include setBorder($blue);
  }

  &.gray{
    @include setBorder($lightGray);
  }

  &.white{
    background-color: $white;
    &.bordered:after{
      border-color: $lightBlue;
    }

    h3{
      color: $lightBlue;
    }
  }

  &:not(.white){
    color: $white;
  }

  &.bordered, &.white{
    overflow: hidden;
    &:before{
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $lightBlue;
      transform: translate3d(-100%, 0, 0);
      transition: transform 200ms;
    }

    &:hover{
      h3, h4{
        color: $white;
      }

      &:before{
        transform: translate3d(0,0,0);
      }
    }

    .wrapper{
      border-color: $lightGray;
    }

    p, h4{
      color: $darkGray;
      backface-visibility: hidden;
    }

    h3, h4{
      transition: color 200ms;
      transform: translateZ(0);
    }
  }

  &.bordered{
    background-color: $white;
    &:after{
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      width: 55px;
      border: 5px solid;
      border-width: 5px 0 5px 5px;
      transform: translateZ(0);
    }
  }

  .wrapper{
    padding: 32px;
    border: 1px solid;
    @include media('<=tablet'){
      padding: 20px;
    }
  }

  h4{
    font-size: 14px;
    font-weight: normal;
    line-height: 1.43;
    letter-spacing: 0.3px;
    margin: 0;
    @include media('<=tablet'){
      font-size: 12px;
    }
  }

  h3{
    font-size: 28px;
    font-weight: bold;
    line-height: 1.14;
    margin: 10px 0;
    @include media('<=tablet'){
      font-size: 22px;
    }
  }

  p{
    font-size: 16px;
    font-weight: normal;
    line-height: 1.38;
    letter-spacing: 0.3px;
    margin: 0;
    transform: translateZ(0);
    transition: opacity 200ms, visibility 200ms;
    @include media('<=tablet'){
      font-size: 14px;
    }
  }

  button{
    position: absolute;
    left: 32px;
    bottom: 32px;
    font-size: 16px;
    font-weight: 300;
    padding: 0;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    border: 0;
    outline: 0;
    color: $white;
    background-color: transparent;
    background-image: url('../../public/images/icons/plus.svg');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: auto 100%;
    padding-left: 40px;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transform: translateZ(0);
    transition: opacity 200ms, visibility 200ms;
  }
}
.quote-block {
  text-align: center !important;
  &:before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    width: 55px;
    border: 5px solid $lightGray;
    border-width: 5px 5px 5px 0;
    transform: translateZ(0);
  }
  span {
      font-size: 38px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.37;
      letter-spacing: 0.9px;
      text-align: center;
      color: $lightBlue;
  }
}
.one-col {
  column-count: 1;
}
.two-col {
  column-count: 2;
}
.text-block {
  background-color: $white;
  position: relative;
  display: block;
  max-width: 100%;
  border: 1px solid $lightGray;
  text-decoration: none;
  padding: 25px;
  margin:20px 0;
  &:after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    width: 55px;
    border: 5px solid $lightGray;
    border-width: 5px 0 5px 5px;
    transform: translateZ(0);
  }
  @include media('<=tablet'){
    &:after {
      border-color: $blue;
    }

    &:before{
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      width: 55px;
      border: 5px solid $blue;
      border-width: 5px 5px 5px 0;
      transform: translateZ(0);
    }
  }
  .block-title {
    max-width: 130px;
    width: 100%;
    margin-left: -180px;
    position: absolute;
    h2 {
      margin: 0;
      font-size: 24px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: 0.4px;
      text-align: right;
      color: $lightBlue;
    }
    .img-wrapper {
      border: 1px solid $lightGray;
    }

    @include media('<=tablet'){
      position: relative;
      margin-left: auto;
      margin-right: auto;
      .img-wrapper {
        border: 0;
        &::before, &::after {
          display: none;
        }
      }
    }
  }

  .file-borders {
    position: relative;
    display: block;
    img {
      max-width: 60px !important;
    }
    &:before{
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      width: 35px;
      border: 5px solid $blue;
      border-width: 5px 5px 5px 0;
      transform: translateZ(0);
    }
    &:after{
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      bottom: 0;
      width: 35px;
      border: 5px solid $blue;
      border-width: 5px 0 5px 5px;
      transform: translateZ(0);
    }
  }
  .block-image {
    max-width: 130px;
    width: 100%;
    margin-left: -180px;
    top: 0px;
    position: absolute;
    .img-wrapper {
      border: 1px solid $lightGray;
      max-width: 100%;
      height: 140px;
      text-align: center;
      img {
        vertical-align: middle;
        top: 50%;
        transform:translateY(-50%);
        position: relative;
        max-width: 125px;
      }
    }
    @include media('<=tablet'){
      position: relative;
      margin-left: auto;
      margin-right: auto;
      .img-wrapper {
        border: 0;
        &::before, &::after {
          display: none;
        }
      }
    }
  }
  .block-text {
    max-width: 100%;
    width: 100%;
  }
  p {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: 0.4px;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: 0.4px;
  }
  h2 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0.4px;
    color: $blue;
  }

  h3 {
    font-size: 24px;
    font-weight: normal;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0.3px;
    color: $lightBlue;
    margin: 0;
  }
  h5 {
    margin: 0;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: 0.5px;
    color: $darkGray;
  }
  button{
    left: 32px;
    bottom: 32px;
    font-size: 16px;
    font-weight: 300;
    padding: 0;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    border: 0;
    outline: 0;
    color: $blue;
    background-color: transparent;
    background-image: url('../../public/images/icons/plus-blue.svg');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: auto 100%;
    padding-left: 40px;
    cursor: pointer;
    margin-top: 20px;
    transform: translateZ(0);
    transition: opacity 200ms, visibility 200ms;
  }
}
.double-block {
  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
  }
}
.report-block {
  min-height: 140px;
  a {
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: block;
    * {
      transition: 100ms;
    }

    &:hover {
      * {
        color: $lightBlue;
      }
    }
  }
}
.gallery-block {
  position: relative;
  margin-top: 60px;
  margin-bottom: 60px;

  .slider-for {
    position: relative;
    margin-bottom: 60px;
  }
  .prev-arrow {
    position: absolute;
    left: -80px;
    bottom: 7px;
    @include media('<=tablet'){
      display: none;
    }
  }

  .next-arrow {
    position: absolute;
    right: -80px;
    bottom: 7px;
    @include media('<=tablet'){
      display: none;
    }
  }
.slider-for__item {
  display: inline-block;
  height: 400px;
  margin: 0 10px;
  background-color: $darkBlue;
  text-align: center;
  color: $white;
  font-size: 2em;
  overflow: hidden;
  position: relative;
  img{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}


.slick-list {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.slider-nav {
  width: 100%;
  position: relative;
  .slick-list {
    overflow: hidden;
  }
}



.slick-slide {
  margin-left: 0px;
  margin-right: 0px;
}
.item-nav {
  width: 140px;
  height: 140px;
  margin-right: 20px;
  overflow: hidden;
  position: relative;
  img{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

}