.success{
  background-color: #f3f3f8;
  padding: 65px 0 240px 0;
  margin-top: 65px;
  @include media('<=tablet'){
    padding-bottom: 80px;
  }

  .btn-blue{
    position: relative;
    z-index: 1000;
    margin: 0 auto;
  }

  @include media('<=tablet'){
    .match-making + & {
      padding-bottom: 40px;
    }
  }
  hr {
    width: calc(100% + 750px);
  }
}

.success-slider{
  position: relative;
  max-width: 880px;
  margin: 140px auto 130px;
  overflow: visible;
  @include media('<=tablet'){
      padding-left: 20px;
      padding-right: 20px;
  }

  .slick-track{
    font-size: 0;
  }

  .slick-list{
    overflow: visible !important;

  }

  .slick-dots{
    position: absolute;
    display: flex;
    left: 50%;
    bottom: -60px;
    transform: translateX(-50%);
    z-index: 1000;
    padding: 0;
    li{
      position: relative;
      display: block;
      list-style-type: none;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #d8d8d8;
      transition: background-color 200ms;
      & + li{
        margin-left: 20px;
      }

      &.slick-active, &:hover{
        background-color: #afafb4;
      }

      button{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 50%;
        opacity: 0;
        cursor: pointer;
      }
    }
  }

  .slick-slide{
    outline: 0;
    text-align: center;
    display: inline-block;
    //border: 1px solid $lightGray;
    vertical-align: top;
    background: transparent;
    transition: 400ms;
    /*&:before, &:after{
      position: absolute;
      content: "";
      top: 30px;
      bottom: 30px;
      width: 55px;
      border: 5px solid $blue;
      opacity: 0.4;
      @include media('<=tablet'){
        display: none;
      }
    }

    &:before{
      left: -75px;
      border-right: 0;
    }

    &:after{
      right: -75px;
      border-left: 0;
    }*/
    &:hover {
      background: rgba(57, 170, 255, 0.2);
    }
  }

  .slick-arrow{
    position: absolute;
    top: 180px;
    font-size: 0;
    border: 0;
    outline: 0;
    width: 40px;
    height: 20px;
    background-color: transparent;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMSIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDMxIDE4Ij4KICAgIDxwYXRoIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjMzlhYWZmIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik0xIDFsMTQuNSAxNUwzMCAxIi8+Cjwvc3ZnPg==');
    background-size: 40px 20px;
    background-repeat: no-repeat;
    background-position: center center;
    transition: transform 200ms;
    cursor: pointer;
  }

  .slick-prev{
    left: -125px;
    transform: translateY(-50%) rotate(90deg);
    &:hover{
      transform: translateY(-50%) translateX(-2px) rotate(90deg);
    }
  }

  .slick-next{
    right: -125px;
    transform: translateY(-50%) rotate(-90deg);
    &:hover{
      transform: translateY(-50%) translateX(2px) rotate(-90deg);
    }
  }

  .content-wrapper{
    padding-bottom: 35px;
    &:before, &:after{
      position: absolute;
      content: "";
      top: -1px;
      bottom: -1px;
      width: 55px;
      border: 5px solid $blue;
    }

    &:before{
      left: -1px;
      border-right: 0;
    }

    &:after{
      right: -1px;
      border-left: 0;
    }

    .img-wrapper{
      margin-top: -70px;
      display: table;
      margin: -70px auto 0 auto;
      height: 150px;
      text-align: center;
      @include media('<=tablet'){
        margin-top: -50px;
        height: auto;
        margin-bottom: 20px;
      }
      .img-inner {
        width: 150px;
        height: 150px;
        background: $white;
        border: solid 1px #979797;
        padding-top: 9px;
        padding-left: 9px;
        margin-right: 15px;
        display: inline-block;
        @include media('<=tablet'){
          width: 100px;
          height: 100px;
          display: block;
          margin: 0 auto 10px;
        }
      }
      img{
        object-fit: cover;
        width: 130px;
        height: 130px;
        @include media('<=tablet'){
          width: 80px;
          height: 80px;
        }
      }
    }

    a{
      font-size: 16px;
      letter-spacing: 0.3px;
      text-decoration: none;
      text-transform: uppercase;
      color: $white;
      backface-visibility: hidden;
      padding: 12px 65px 12px;
      text-align: center;
      overflow: hidden;
      border: 2px solid #0066c3;
      transition: background-color .2s,color .2s;
      position: relative;
      background-color: $blue;
      &:before {
        content: '';
        background-image: url('../../public/images/icons/plus.svg');
        position: absolute;
        background-repeat: no-repeat;
        background-position: left center;
        background-size: auto 100%;
        top: 10px;
        right: 0;
        bottom: 10px;
        left: 20px;
        opacity: 0;
        visibility: hidden;
        transition:300ms;
      }
      &:hover{
        background: #0066c3;
        color: $white;
        &:before {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  h4{
    font-size: 20px;
    font-weight: 600;
    line-height: 1.6;
    letter-spacing: 0.3px;
    margin: 0;
    margin-left: 10px;
    text-align: left;
    color: #0063c6;
    height: inherit;
    vertical-align: bottom;
    display: table-cell;
    @include media('<=tablet'){
      display: block;
      margin-left: 5%;
      max-width: 90%;
      text-align: center;
    }
    span{
      display: block;
      font-weight: normal;
    }
    span.secondTitle {
      font-weight: 600;
    }
  }

  p{
    max-width: 720px;
    margin: 30px auto 50px;
    text-align: left;
    width: 100%;
    font-size: 18px;
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0.5px;
    color: $darkGray;
    @include media('<=tablet'){
      font-size: 18px;
      padding-left: 30px;
      padding-right: 30px;
      margin-top: 0;
    }
  }
}