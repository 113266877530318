.corporation-items{
  padding: 55px 0 75px;
  .container{
    display: flex;
    justify-content: space-between;
    @include media('<=tablet'){
      display: block;
    }
    .box-item{
      & + .box-item{
        margin-left: 20px;
        @include media('<=tablet'){
          margin: 20px 0 0;
        }
      }
      .wrapper {
        min-height: 311px;

        @include media('<=tablet'){
          min-height: 200px;
        }
      }
    }
  }
}
.faded {
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    z-index: -1;
    opacity: 0.4;
  }
}

.match-making, .match-making-as-service, .steps-section{
  .intro-text{
    color: $darkGray;
    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: 0.3px;
    max-width: 760px;
    @include media('<=tablet'){
      font-size: 16px;
      line-height: 1.38;
      letter-spacing: 0.5px;
    }
    a {
      text-decoration: none;
      color: $darkGray;
      transition:300ms;
      &:hover {
        color: $blue;
      }
    }
  }
}
.color-text {
  p {
    color: $darkGray;
    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: 0.3px;
    max-width: 760px;
    @include media('<=tablet'){
      font-size: 16px;
      line-height: 1.38;
      letter-spacing: 0.5px;
    }
  }
    a {
      text-decoration: none;
      color: $darkGray;
      transition:300ms;
      font-size: 18px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.56;
      letter-spacing: 0.3px;
      font-weight: bold;
      &:hover {
        color: $blue;
      }
      @include media('<=tablet'){
        font-size: 16px;
        line-height: 1.38;
        letter-spacing: 0.5px;
      }
    }
}

.match-making{
  margin-top: 35px;
  @include media('<=tablet'){
    background-color: #f3f3f8;
  }
  hr {
    @include media('<=tablet'){
      left: -20px !important;
      width: calc(100% - 25px) !important;
    }
  }

  .gray-section{
    position: relative;
    &:before{
      position: absolute;
      content: "";
      background-color: #f3f3f8;
      z-index: -1;
      @include fullWidth();
    }

    &:first-of-type{
      .intro-text{
        padding: 35px 0 60px;
      }

      &:before{
        top: -50px;
        bottom: 0;
      }
    }

    &:last-of-type{
      padding: 35px 0;
      margin: 40px 0 -60px;
      &:before{
        top: 0;
        bottom: -50px;
      }
    }
  }

  .intro-text{
    margin: 0;
    & + p{
      padding: 0;
      margin-top: 20px;
    }
  }
}

.match-making-advisory{
  .intro-text{
    margin: 50px 0 40px;
    max-width: 600px;
  }

  .container{
    padding-bottom: 0;
  }

  .items{
    position: relative;
    padding: 20px 0 50px;
    &:before{
      position: absolute;
      content: "";
      top: 0;
      bottom: -40px;
      background-color: $darkBlue;
      @include fullWidth();
      z-index: -1;
      background-image: url('../../public/images/BG_corporations_Match_Making_advisory.jpg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
    .item{
      display: flex;
      margin-top: 40px;
      @include media('<=tablet'){
        display: block;
        & + .item{
          margin-top: 80px;
        }
      }
    }
    .description{
      width: 100%;
      max-width: 175px;
      margin-right: 25px;
      color: $white;
      @include media('<=tablet'){
        margin: 0 auto 25px;
        max-width: none;
      }
      h4{
        position: relative;
        margin: 30px 0 32px;
        font-size: 20px;
        font-weight: bold;
        line-height: 1.1;
        letter-spacing: 0.3px;
        @include media('<=tablet'){
          margin-bottom: 15px;
          font-size: 22px;
        }
        &:before{
          position: absolute;
          content: "";
          bottom: -16px;
          border-bottom: 1px solid $white;
          left: calc(-100vw + 940px);
          width: calc(100vw - 740px);
          @include media('<=tablet'){
            display: none;
          }
        }
      }

      p{
        font-weight: 300;
        line-height: 1.5;
        letter-spacing: 0.3px;
      }
    }

    .info{
      margin: 0;
      padding: 15px 25px;
      border: 2px solid $white;
      font-size: 16px;
      line-height: 1.38;
      letter-spacing: 0.5px;
      color: $white;
    }
  }
}
.succes-story-corporations {
  margin-top: 40px;
  hr {
    @include media('<=tablet'){
      left: -20px !important;
      width: calc(100% - 25px) !important;
    }
  }
}
.portfolio-items{
  padding: 10px 0 40px;
  @include media('<=tablet'){
    margin-top: -80px;
  }

  .single{
    display: flex;
    margin-top: 50px;
    @include media('<=tablet'){
      flex-wrap: wrap;
    }
    .item-wrap {
      @include media('<=tablet'){
        margin: 20px 0 0;
        width: calc(50% - 10px);
        &:nth-of-type(2n){
          margin-right: 20px;
        }
      }
    }
    h4{
      position: relative;
      margin: 30px 0 32px;
      font-size: 20px;
      font-weight: bold;
      line-height: 1.1;
      letter-spacing: 0.3px;
      color: #6b6b6f;
      @include media('<=tablet'){
        margin-bottom: 15px;
      }
      &:before{
        position: absolute;
        content: "";
        bottom: -16px;
        border-bottom: 1px solid #979797;
        @include fullWidth();
        @include media('<=tablet'){
          display: none;
        }
      }
    }

    p{
      font-size: 12px;
      font-weight: 300;
      line-height: 1.5;
      letter-spacing: 0.3px;
      color: $darkGray;

      @include media('<=tablet'){
        font-size: 14px;
      }
    }

    .description{
      width: 100%;
      max-width: 175px;
      margin-right: 25px;
      @include media('<=tablet'){
        min-width: 100%;
        margin-right: 0;
      }
    }

    .item{
      position: relative;
      width: 140px;
      margin-left: 20px;
      z-index: 2;
      @include media('<=tablet'){
        margin: 20px 0 0;
       /* width: calc(50% - 10px);*/
        &:nth-of-type(2n){
          margin-right: 20px;
        }
      }
      .img-wrapper{
        position: relative;
        min-height: 140px;
        text-align: center;
        border: 1px solid #979797;
        background-color: $white;
        cursor: pointer;
        &:before{
          position: absolute;
          content: "";
          top: -1px;
          left: -1px;
          right: -1px;
          bottom: -1px;
          background-color: rgba(57, 170, 255, 0.8);
          z-index: 2;
          transition: transform 200ms;
          transform: scaleY(0);
          transform-origin: 50% 100%;
          backface-visibility: hidden;
        }
        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 10;
          background-image: url('../../public/images/icons/plus.svg');
          background-repeat: no-repeat;
          background-position: center center;
          background-size: auto;
          visibility: hidden;
          opacity: 0;
          transition: opacity 200ms;
          transition-delay: 100ms;
        }
        &:hover{
          &:before{
            transform: scaleY(1);
          }
          &::after {
            opacity: 1;
            visibility: visible;
          }
        }
        img{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-width: 100%;
        }
      }

      p{
        margin: 15px 0 0;
        span{
          display: block;
        }
      }
    }
  }
}

.match-making-as-service{
  margin: 100px 0 80px;
  @include media('<=tablet'){
    margin-bottom: 50px;
  }

  hr {
    @include media('<=tablet'){
      left: -20px !important;
      width: calc(100% - 25px) !important;
    }
  }
  .steps{
    &:before{
      background-image: url('../../public/images/BG_corporations_match_making_as_a_service.jpg');
    }
  }

  .btn{
    @include media('<=tablet'){
      margin-top: 40px;
    }
  }
}

.match-making-as-service, .steps-section{
  overflow: hidden;
  .intro-text{
    margin: 50px 0 40px;
  }
  .steps{
    position: relative;
    display: flex;
    margin-bottom: 40px;
    padding: 95px 0;
    @include media('<=tablet'){
      display: block;
      padding-left: 100px;
      margin-bottom: 0;
    }

    @include media('<=mobile'){
      padding-left: 40px;
    }

    &:before{
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      background-color: $blue;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      z-index: -1;
      @include fullWidth();
      @include media('<=tablet'){
        background-image: none !important;
      }
    }

    &:after{
      position: absolute;
      content: "";
      top: 65px;
      border-bottom: 2px solid $white;
      @include fullWidth();
      @include media('<=tablet'){
        width: 2px;
        top: 0;
        bottom: 0;
        left: 78px;
        margin-left: 0;
        background-color: $white;
        border: 0;
      }
      @include media('<=mobile'){
        left: 18px;
      }
    }
  }
  .step-item{
    position: relative;
    width: 25%;
    padding: 0 25px;
    text-align: left;
    @include media('<=tablet'){
      width: 100%;
      text-align: left;
      & + .step-item{
        margin-top: 65px;
      }
    }

    .small-circle{
      position: absolute;
      top: -38px;
      left: 25px;
      z-index: 2;
      @include media('<=tablet'){
        top: 3px;
        left: -30px;
        background-color: $blue;
      }

      &:before{
        border-color: $white;
        background-color: $blue;
      }

      &:after{
        background-color: $white;
      }
    }

    h4{
      font-size: 20px;
      font-weight: 600;
      line-height: 1.1;
      letter-spacing: 0.3px;
      color: #a1d7ff;
      margin: 0 0 25px;
      @include media('<=tablet'){
        font-weight: 400;
      }
    }

    p{
      font-size: 14px;
      font-weight: 300;
      line-height: 1.57;
      letter-spacing: 0.4px;
      color: $white;
      margin: 0;
    }
  }
}
.steps-section{
  .step-item {
    text-align: center;
    .small-circle {
      left: 50%;
      transform: translateX(-50%);
    }
  }
 }
.find-out-process{
  @include media('<=tablet'){
    margin-bottom: -30px;
  }

  h4{
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: 0.3px;
    margin: 0 0 15px;
    color: $darkGray;
  }

  p{
    font-size: 16px;
    font-weight: 300;
    line-height: 1.38;
    letter-spacing: 0.3px;
    margin: 0;
    color: $darkGray;
  }
}

.find-out-list{
  position: relative;
  padding-left: 250px;
  @include media('<=tablet'){
    padding-left: 0;
  }
  &:before{
    position: absolute;
    content: "";
    top: 70px;
    left: calc(-100vw + 620px);
    bottom: 0;
    border: 2px solid $blue;
    border-width: 2px 2px 2px 0;
    width: calc(100vw - 420px);
    @include media('<=tablet'){
      width: 500px;
      left: -423px;
    }
    @include media('<=mobile'){
      left: -481px;
    }
  }

  h2{
    position: relative;
    color: $blue;
  }

  & > h2{
    padding: 60px 0;
    margin: 0;
    @include media('<=tablet'){
      padding-left: 128px;
    }

    @include media('<=mobile'){
      padding-left: 70px;
    }

    &:before{
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      background-color: #f3f3f8;
      z-index: -1;
      @include fullWidth();
    }
  }

  ul{
    padding: 60px 0;
    margin: 0;
    @include media('<=tablet'){
      padding-left: 128px;
    }

    @include media('<=mobile'){
      padding-left: 70px;
    }
  }

  li{
    position: relative;
    display: block;
    max-width: 380px;
    .small-circle{
      position: absolute;
      left: -59px;
      &:before{
        background-color: $white;
        border-color: $blue;
      }

      &:after{
        background-color: $blue;
      }
    }

    & + li{
      margin-top: 35px;
    }
  }
}

.find-out-engagement{
  position: relative;
  padding: 43px 0 40px;
  margin: 80px 0 40px;
  &:before{
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    background-color: #f3f3f8;
    @include fullWidth();
    z-index: -1;
  }
  h2{
    color: $blue;
    text-align: center;
    margin: 0 0 40px;
  }
  .items{
    display: flex;
    justify-content: center;
    @include media('<=tablet'){
      display: block;
      padding: 0 20px;
    }
  }

  .item{
    position: relative;
    margin: 0 10px;
    border: 1px solid $blue;
    max-width: 300px;
    padding: 40px 25px 40px 30px;
    @include media('<=tablet'){
      max-width: 100%;
      & + .item{
        margin-top: 20px;
      }
    }
    &:before{
      position: absolute;
      content: "";
      top: 50%;
      width: 100vw;
      border-bottom: 1px solid $blue;
    }

    &:first-of-type{
      &:before{
        left: -100vw;
      }
    }

    &:last-of-type{
      &:before{
        right: -100vw;
      }
    }

    h4{
      line-height: 1.6;
      margin-bottom: 25px;
      color: #6b6b6f;
    }
  }
}
.popup-wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  z-index: -10000;
  transition: opacity 300ms;
  &:before {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $blue;
    opacity: 0.8;
    z-index: -1;
  }
}
.item-popup {
  width: 980px;
  height: 690px;
  padding-bottom: 30px;
  background: $white;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  position: relative;
  padding-left: 60px;
  padding-right: 60px;
  display: flex;
  @include media('<=tablet'){
    width: 90%;
    height: auto;
    flex-wrap: wrap;
    padding-left: 20px;
    padding-right: 20px;
  }
  .popup-left {
    max-width: 180px;
    width: 100%;
    margin-right: 40px;
    @include media('<=tablet'){
      max-width: 100%;
      margin-right: 0;
    }
  }
  .img-wrapper{
    position: relative;
    min-height: 180px;
    text-align: center;
    border: 1px solid #979797;
    background-color: $white;
    top: -60px;
    margin-bottom: -40px;
    cursor: pointer;
    @include media('<=tablet'){
      min-height: 259.5px;
      top: 60px;
      margin-bottom: 0px;
    }
    &:before{
      position: absolute;
      content: "";
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      background-color: rgba(57, 170, 255, 0.8);
      z-index: 2;
      transition: transform 200ms;
      transform: scaleY(0);
      transform-origin: 50% 100%;
      backface-visibility: hidden;
    }

    &:hover{
      &:before{
        transform: scaleY(1);
      }
    }
    img{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 100%;
      @include media('<=tablet'){
        max-width: calc(100% - 2px);
        max-height: 250px;
      }
    }
  }
  .cnt-wrapper {
    background: #f3f3f8;
    max-width: 180px;
    width: 100%;
    padding: 20px 10px;
    min-height: 320px;
    margin-bottom: 20px;
    p {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.57;
      letter-spacing: 0.4px;
      color: #6b6b6f;
      strong {
        font-weight: 600;
      }
    }
    @include media('<=tablet'){
      min-height: initial;
      margin-top: 80px;
      max-width: 100%;
    }
  }
  .box-wrap {
    position: relative;
    z-index: 1;
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 1px solid $lightGray;
      z-index: -1;
    }
    &:before {
      position: absolute;
      content: "";
      top: -1px;
      left: 0;
      right: 0;
      bottom: -1px;
      background-color:$blue;
      transform: translate3d(-100%, 0, 0);
      transition: transform 200ms;
      z-index: 1;
    }
    &:hover {
      &:before {
        transform: translate3d(0, 0, 0);
      }
      a {
        border-color: $blue;
      }
      p {
        color: $white;
      }
      button {
        color: $white;
      }
      span {
        opacity: 0;
      }
    }
    a{
      position: relative;
      display: block;
      text-decoration: none;
      width: 100%;
      padding: 20px;
      z-index: 1;
      @include media('<=tablet'){
        width: 100%;
        padding: 15px 25px;
      }
      &:before, &:after{
        position: absolute;
        content: "";
        top: -1px;
        bottom: -1px;
        width: 35px;
        border-style: solid;
      }
      &:before{
        left: -1px;
        border-width: 5px 0 5px 5px;
        border-color: $blue;
      }
      &:after{
        right: -1px;
        border-width: 5px 5px 5px 0;
        border-color: $blue;
      }
    }
    p {
      font-size: 12px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.57;
      letter-spacing: 0.4px;
      color: #6b6b6f;
      transition:300ms;
    }
    strong {
      font-weight: 600;
    }

    span {
      font-size: 10px;
      font-weight: normal;
      line-height: 1.83;
      color: #6b6b6f;
      transition:300ms;
    }
    button{
      position: relative;
      font-size: 12px;
      font-weight: 300;
      padding: 0;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      border: 0;
      outline: 0;
      color: $blue;
      text-align: center;
      margin: auto;
      left: 50%;
      transform: translateX(-50%);
      background-color: transparent;
      transition:300ms;
    }
  }
  .hideOnDesktop {
    display: none;
    @include media('<=tablet'){
      display: block;
    }
  }
  .hideOnMobile {
    display: block;
    @include media('<=tablet'){
      display: none;
    }
  }
  @include media('<=tablet'){
    max-width: 100%;
  }
  .popup-right {
    max-width: 630px;
    width: 100%;
    padding-top: 60px;
    @include media('<=tablet'){
      max-width: 100%;
    }
    h2 {
      margin: 0;
      font-size: 20px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: 0.3px;
      color: #4a4a4a;
    }
    h3 {
      margin: 0;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.57;
      letter-spacing: 0.3px;
      color: #4a4a4a;
    }
    a {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.25;
      letter-spacing: 0.3px;
      color: $blue;
      text-decoration: none;
      margin-top: 15px;
      margin-bottom: 40px;
      display: block;
      transition: 300ms;
      &:hover {
        color: $darkBlue;
      }
    }
    p {
      font-size: 14px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.57;
      margin: 0;
      letter-spacing: 0.3px;
      color: #4a4a4a;
    }
    .right-inner {
      padding: 10px;
      width: 100%;
      margin-bottom: 20px;
      border: 1px solid $lightGray;
      &:first-of-type {
        margin-top: 30px;
      }
    }
    .blue {border-color: $blue;}
    .light-blue {border-color: $lightBlue;}
    .dark-blue {border-color: $darkBlue;}
  }
  .close-popup {
    position: absolute;
    width: 35px;
    height: 35px;
    background: url(../../public/images/icons/close.svg);
    background-repeat: no-repeat;
    background-position: center center;
    top: 20px;
    right: 60px;
    cursor: pointer;

    @include media('<=tablet'){
      top: 10px;
      right: 15px;
    }
  }
}
.active-popup {
  opacity: 1;
  visibility: visible;
  z-index: 10000000000000000;
}