.newsletter{
  padding: 50px 0 40px;
  background-color: $blue;
  background-image: url('../../public/images/BG_MMV_newsletter.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  @include media('<=tablet'){
    padding: 40px 0;
  }
  h3{
    font-size: 26px;
    font-weight: 300;
    letter-spacing: 0.3px;
    color: $white;
    margin: 0 0 30px;
    @include media('<=tablet'){
      font-size: 22px;
      font-weight: 300;
      line-height: 1.27;
      letter-spacing: 0.3px;

      .bold{
        display: block;
      }
    }
  }

  form{
    display: flex;
    @include media('<=tablet'){
      display: block;
    }

    //input[type="text"] i input[type="email"] ne radi na nuxtu nesto pa zasad samo input

    .btn{
      @include media('<=tablet'){
        max-width: 100%;
      }
    }

    input{
      margin-right: 20px;
      border-style: solid;
      border-color: #a1d7ff;
      border-width: 1px;
      background-color: transparent;
      outline: 0;
      font-size: 22px;
      font-weight: 300;
      color: $white;
      padding: 0 35px;
      width: 100%;
      max-width: 300px;
      @include media('<=tablet'){
        max-width: 100%;
        padding: 8px 20px;
        margin-bottom: 20px;
      }

      &::-webkit-input-placeholder{
        color: #a1d7ff;
      }
      &::-moz-placeholder{
        color: #a1d7ff;
      }
      &:-ms-input-placeholder{
        color: #a1d7ff;
      }
      &:-moz-placeholder{
        color: #a1d7ff;
      }
    }
  }
}