.insights {
  .main-header {
    .container {
      min-height: 400px;
    }
  }
}
.insights-wrapper{
  padding-top: 60px;

  select{
    height: 60px;
    border-radius: 0;
  }
}
.list-wrap {
  display: flex;
  max-width: 620px;
  height: 100px;
  margin-bottom: 45px;
  & > h2{
    font-size: 28px;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.3px;
    color: #6b6b6f;
    margin: 0;
    margin-right: 50px;
    position: relative;
    transform: translateY(-50%);
    top: 50%;
    height: 35px;
  }
  & > form{
    width: 300px;
    position: relative;
    transform: translateY(-50%);
    top: 50%;
    height: 60px;
  }
}

.insights-list{
  & > h2{
    font-size: 28px;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: 0.3px;
    color: #6b6b6f;
    margin: 0 0 55px;
  }

  .insights-item{
    max-width: 300px;
    @include media('<=tablet'){
      max-width: 50%;
      min-width: 100%;
    }

    @include media('<=mobileNarrow'){
      max-width: 100%;
      min-width: 100%;
    }
  }

  .featured{
    position: relative;
    margin-bottom: 100px;
  }

  .gray-title{
    position: absolute;
    top: 55px;
    left: 660px;
    margin: 0;
    color: $white;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.75;
    letter-spacing: 1px;
    text-transform: uppercase;
    &:before{
      position: absolute;
      content: "";
      top: -15px;
      left: -100vw;
      bottom: -15px;
      width: 200vw;
      background-color: #6b6b6f;
      z-index: -1;
    }
  }
  .insights-slider {
    display: block;
    position: relative;
    min-height: 415px;
  }
  .featured-slide{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    @include media('<=tablet'){
      display: block;
    }
    .img-wrapper{
      width: 100%;
      max-width: 620px;
      @include media('<=tablet'){
        max-width: 100%;
        margin-left: -20px;
      }
    }
    .insights-item {
      @include media('<=tablet'){
        max-width: 100%;
        display: block;
        margin: -40px 0 0 0 !important;
        .text-wrapper {
          left: 50%;
          transform: translateX(-50%);
          background: $white;
          height: auto;
          p {
            font-size: 20px;
          }
        }
      }
    }
    img{
      object-fit: cover;
      width: 100%;
      height: 415px;
      @include media('<=tablet'){
        height: 215px;
        width: calc(100% + 40px);
      }
    }

    .insights-item{
      margin: 0 0 6px 22px;
    }
  }
}
.insights-slider {
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: -20px;
    bottom: 0;
    left: -20px;
    background: #f3f3f8;
    display: none;
    @include media('<=tablet'){
      display: block;
    }
  }
  @include media('<=tablet'){
    padding-bottom: 130px;
  }
}
.insights-items{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  margin: -40px 0 90px;

  .insights-item{
    margin-top: 40px;
  }

  .btn{
    margin: 0px auto;
  }
  @include media('<=tablet'){
    grid-template-columns: repeat(2, 1fr);
  }

  @include media('<=mobileNarrow'){
    grid-template-columns: repeat(1, 1fr);
  }
}
.paginationWrap {
  margin-bottom: 135px;
}
.blog {
  &__Nav {
    width: 100%;
    text-align: center;
    a {
      display: block;
      text-decoration: none;
      margin: auto;
      text-align: center;
      .btn {
        margin: auto;
      }
    }
  }
}

.center-load {
  display: block;
  width: 100%;
  text-align: center;
}

.featured-slider-navigation{
  position: absolute;
  top: 130px;
  right: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 300px;
  padding: 0 17px;
  margin: 0;
  z-index: 100000;
  @include media('<=tablet'){
    top: auto;
    bottom: 50px;
  }
  &:before{
    position: absolute;
    content: "";
    left: 17px;
    right: 17px;
    border-bottom: 1px solid #a6a6ad;
    top: 8px;
  }

  li{
    position: relative;
    font-size: 0;
    display: inline-block;
    min-width: 18px;
    min-height: 18px;
    border-radius: 50%;
    background-color: #fff;
    @include media('<=tablet'){
      background-color: #f3f3f8;
    }
    cursor: pointer;
    & + li{
      margin-left: 25%;
    }

    button {display: none;}
      &:before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        border: 1px solid #a6a6ad;
        border-radius: 100%;
      }

      &:after{
        content: '';
        position: absolute;
        top: 5px;
        right: 5px;
        bottom: 5px;
        left: 5px;
        z-index: 1;
        opacity: 0;
        border-radius: 100%;
        background-color: #a6a6ad;
        transition: opacity 200ms;
        backface-visibility: hidden;
      }


    &:hover, &.slick-active{
      &:after{
        opacity: 1;
      }
    }
  }
}