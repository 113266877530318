.insights-item{
  text-decoration: none;
  min-width: 300px;
  /*@include media('<=tablet'){
    display: flex;
    justify-content: space-between;
    min-width: initial;
  }*/
  &.no-img{
    img{
      display: none;
    }
  }
  .img-wrapper {
    position: relative;
    z-index: 1;
    overflow: hidden;
    border: 1px solid #dedede;
    margin-bottom: 10px;
    height: 200px;
    @include media('<=tablet'){
      height: 300px;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $blue;
      opacity: 0.8;
      height: 200px;
      transform: translate3d(-100%, 0, 0);
      transition: transform 200ms;
      @include media('<=tablet'){
        height: 300px;
      }
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      box-shadow: inset 0 0 10px rgba(0,0,0,0.3);
    }
    button{
      position: absolute;
      left: 12px;
      bottom: 32px;
      font-size: 16px;
      font-weight: 300;
      padding: 0;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      border: 0;
      outline: 0;
      color: $white;
      background-color: transparent;
      background-image: url('../../public/images/icons/plus.svg');
      background-repeat: no-repeat;
      background-position: left center;
      background-size: auto 100%;
      padding-left: 35px;
      cursor: pointer;
      opacity: 0;
      visibility: hidden;
      transform: translateZ(0);
      transition: opacity 200ms, visibility 200ms;
    }

    /*@include media('<=tablet'){
      max-width: 30%;
      img {
        max-width: 100%;
        width: 100%;
      }
    }*/
  }
  &:hover {
    .img-wrapper {
      &:before {
        transform: translate3d(0, 0, 0);
      }
      button {
        opacity: 1;
        visibility: visible;
      }
    }
    .text-wrapper {
      &:before {
        border-color: $blue;
      }
      &:after {
        transform: translate3d(0, 0, 0);
      }
    }
    /*@include media('<=tablet'){
      .img-wrapper {
        &:before {
          display: none;
        }
        button {
          display: none;
        }
      }
      .text-wrapper {
        &:after {
          display: none;
        }
        &::before {
          border-color: #dedede;
        }
      }
    }*/
  }
  img{
    object-fit: cover;
    width: 300px;
    height: 200px;
    @include media('<=tablet'){
      width: 100%;
      height: 300px;
    }
  }

  .text-wrapper{
    position: relative;
    border: 1px solid #dedede;
    padding: 15px;
    overflow: hidden;
    min-height: 170px;
    max-height: 170px;
    /*@include media('<=tablet'){
      max-height: 200px;
    }*/
    @include media('<=tablet'){
      padding: 25px 15px;
      min-height: initial;
      max-height: initial;
    }
    &:before{
      position: absolute;
      content: "";
      top: -1px;
      left: -1px;
      bottom: -1px;
      width: 55px;
      border-style: solid;
      border-width: 5px 0 5px 5px;
      transition:300ms;
      border-color: #d7d7de;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #f3f3f8;
      height: 200px;
      z-index: -1;
      transform: translate3d(-100%, 0, 0);
      transition: transform 200ms;
    }
    h4{
      font-size: 14px;
      font-weight: 600;
      line-height: 1.5;
      letter-spacing: 1px;
      margin: 0 0 15px;
      color: $lightBlue;
      text-transform: uppercase;
      &:after{
        content: "";
        display: block;
        margin-top: 3px;
        border-bottom: 1px solid $lightBlue;
      }
      @include media('<=tablet'){
        font-size: 12px;
      }
    }

    p{
      font-size: 20px;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: 0.3px;
      color: #6b6b6f;
      margin: 0px 0 0;
      @include media('<=tablet'){
        font-size: 16px;
      }
    }
  }
  &:hover {

  }
}