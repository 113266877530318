$white: #fff;
$blue: #0066c3;
$lightBlue: #39aaff;
$darkBlue: #072e5c;
$darkGray: #4a4a4a;
$lightGray: #dedede;
$yellow: #ffc731;

@mixin pseudoCircle(){
  &:before, &:after{
    position: absolute;
    content: "";
    border-radius: 50%;    
  }
  
  &:before{
    width: 16px;
    height: 16px;
    border-style: solid;
    border-width: 2px;
  }
  
  &:after{
    width: 8px;
    height: 8px;
  }
}

@mixin fullWidth(){
  left: -100vw;
  width: 200vw;
  margin-left: calc(100vw - 940px);
  
  @include media('<=tablet'){
    margin-left: calc(100vw - 40px);    
  }
}