.main-header{
  position: relative;
  margin-bottom: 100px;
  z-index: 2;
  @include media('<=tablet'){
    margin-bottom: 50px;
  }
  .container{
    position: relative;
    min-height: 500px;    
  }
  
  &.light-blue{
    .text-wrapper{
      background-color: $lightBlue;
    }
  }
  
  &.no-after{
    .text-wrapper:after, .circle-filled{
      @include media('>tablet'){
        display: none;
      }
    }
  }
  
  &.no-lines{
    .text-wrapper:before, .text-wrapper:after, .circle{
      display: none;        
    }
  }
  
  &.dark-blue{
    .text-wrapper{
      background-color: $darkBlue;
    }
  }
  
  .text-wrapper{
    position: absolute;
    left: 0;
    bottom: -100px;
    background-color: $blue;
    color: $white;
    max-width: 380px;
    min-height: 380px;
    max-height: 380px;
    width: 100%;
    padding: 45px;
    @include media('<=tablet'){
      padding: 25px 30px;
      min-height: 255px;
      max-width: 255px;
      left: 50%;
      transform: translateX(-50%);
      bottom: -50px;
    }
    
    &:before, &:after{
      position: absolute;
      content: "";
      top: 92px;      
      width: 100vw;
      border-bottom: 2px solid $yellow;
      @include media('<=tablet'){
        top: 76px;
      }
    }
    &:before{
      left: -100vw;
      margin-left: -24px;
      @include media('<=tablet'){
        margin-left: -16px;
      }
    }
    
    &:after{
      right: -100vw;
      margin-right: -24px;
      @include media('<=tablet'){
        margin-right: -16px;
      }
    }
  }
  
  .circle-filled, .circle-empty{
    position: absolute;
    top: 45px;
  }
  
  .circle-filled{
    right: 0;
    transform: translate3d(50%, 50%, 0);
  }
  
  .circle-empty{
    left: 0;
    transform: translate3d(-50%, 50%, 0);
  }
  
  .default-wrapper {
    min-height: 140px;
    max-height: 140px;
    bottom: 0;
    &:before, &:after{
      top: 72px;
    }
    .circle-filled, .circle-empty {
      top: 25px;
    }
  }
  img{
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  
  h1{
    margin: 0;
    span{
      display: block;
      &:first-child, p{
        font-size: 24px;
        font-weight: 300;
        line-height: 1.34;
        letter-spacing: 0.3px;
        @include media('<=tablet'){
          font-size: 16px;
          line-height: 1.38;
        }
        span{
          margin-top: -5px;
        }
      }
      
      &:not(:first-child), &:first-child span{
        font-size: 46px;
        font-weight: bold;
        line-height: 1.13;
        letter-spacing: 0.2px;
        @include media('<=tablet'){
          font-size: 32px;
        }
      }
    }
    
    p{
      border-top: 1px solid $white;
      margin: 25px 0 0;
      padding-top: 33px;
    }
  }
}