@mixin activeCircle($n, $numberOfSteps){
  $part: (100% / $numberOfSteps) * $n;
  $percentage: $part - ((100 / $numberOfSteps) / 2);
  &:after{
    width: calc(100vw + #{$percentage});            
  }
  li:nth-child(#{$n}){
    color: $lightBlue;    
    .small-circle{
      &:before{
        background-color: $lightBlue;
        border-color: $lightBlue;            
      }
      
      &:after{
        opacity: 1;
        background-color: $white;
      }
    }
  }
  
  li:nth-child(-n + #{$n - 1}){
    color: $lightBlue;
    .small-circle{
      &:before{
        border-color: $lightBlue;
      }
      
      &:after{
        opacity: 1;
        background-color: $lightBlue;
      }
    }
  }
}

.submission-form{
  display: flex;
  flex-direction: column;
  margin: 60px 0 100px;
  .subtitle{
    font-size: 28px;
    color: $lightBlue;

    & + h5{
      font-size: 20px;
      line-height: 1.5;
      letter-spacing: 0.3px;
      color: $darkGray;
      font-weight: 300;
      margin: 0 0 35px;
    }
  }
  .half, .quarter, .medium, .small{
    width: 100%;
  }
  
  .half{
    max-width: 460px;      
  }
  
  .quarter{
    max-width: 220px;
  }
  
  .medium{
    max-width: 300px;
  }
  
  .small{
    max-width: 140px;
  }
  
  .inputs-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  
  label{
    margin-top: 30px;
  }
  
  textarea{
    min-height: 240px;
  }
}

.submision-steps{
  margin-top: -100px;
  padding: 225px 0 60px;
  background-color: #f3f3f8;
  .steps-circles{
    position: relative;
    padding: 0;
    margin: 0;
    display: flex;
    list-style-type: none;
    &:before, &:after{
      position: absolute;
      content: "";
      top: -37px;
      left: -100vw;
      border-bottom: 2px solid;
    }
    
    &:before{
      width: calc(100vw + 87.5%);
      border-color: #a6a6ad;
    }
    
    &:after{
      border-color: $lightBlue;
    }
    
    &.filled-1{
      @include activeCircle(1, 4);
    }
    
    &.filled-2{
      @include activeCircle(2, 4);     
    }
    
    &.filled-3{
      @include activeCircle(3, 4);      
    }
    
    &.filled-4{
      @include activeCircle(4, 4);      
    }
    
    li{
      position: relative;
      font-size: 20px;
      font-weight: 600;
      line-height: 1.1;
      letter-spacing: 0.3px;
      text-align: center;
      color: #a6a6ad;
      width: 25%;
      transition: color 200ms;
      backface-visibility: hidden;
      
      .small-circle{
        position: absolute;
        top: -45px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        &:before{
          background-color: #f3f3f8;
          border-color: #a6a6ad;
          transition: border-color 200ms, background-color 200ms;
          backface-visibility: hidden;
        }
        
        &:after{
          transition: opacity 200ms;
          backface-visibility: hidden;
          opacity: 0;
        }
      }
    }
  }
}

