.company{
    .main-header {
      .container {
        min-height: 600px;
      }
    }
  .steps-section{
    padding-top: 100px;
    @include media('<=tablet'){
      padding-top: 50px;
    }
    .container{
      padding-bottom: 0;
    }
    .steps{
      &:before{
        bottom: -80px;
        background-color: $blue;
      }
      &:after {
        @include media('<=tablet'){
          left: 18px;
        }
      }
      @include media('<=tablet'){
        padding-left: 0;
      }
      .step-item {
        text-align: left;
        @include media('<=tablet'){
          text-align: left;
          padding-left: 50px;
        }
        .small-circle {
          left: 35px;
          @include media('<=tablet'){
            border-radius: 50%;
            display: inline-block;
            left: 10px;
            transform: translateX(0);
          }
        }
      }
    }
  }


  .our-match-makers{
    margin-top: 80px;
      @include media('<=tablet'){
        margin-top: 50px;
      }
  }

  .team-items{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 20px;
    margin-top: 100px;
    @include media('<=tablet'){
      grid-template-columns: repeat(2, 1fr);
      justify-content: center
    }
    @include media('<=600px'){
      grid-template-columns: repeat(1, 0fr);
      justify-content: center
    }
    .team-item{
      margin-bottom: 60px;
      @include media('<=tablet'){
        margin: 20px 0 0;
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
        .description .hidden-text p {
          max-width: 100% !important;
        }
        &:nth-of-type(2n){
          margin-right: 20px;
        }
      }
    }
  }

  .left-section{
    .subtitle{
      @include media('<=tablet'){
        max-width: 80%;
      }
    }
    p{
      max-width: 430px;
      margin: 25px 0 0;
      font-size: 20px;
      font-weight: 300;
      line-height: 1.6;
      letter-spacing: 0.3px;
      color: $darkGray;
      @include media('<=tablet'){
        max-width: 80%;
        margin-top: 40px;
        font-size: 16px;
        line-height: 1.38;
        letter-spacing: .5px;
      }
    }

    .btn{
      margin: 45px 0;
    }

    .img-right{
      position: absolute;
      top: -135px;
      right: 0;
      max-width: 410px;
      @include media('<=tablet'){
        max-width: initial;
        max-height: 180px;
        top: -40px;
        right: -149px;
      }
    }

    hr{
      width: calc(100% + 620px);
      @include media('<=tablet'){
        width: calc(100% - 45px);
      }
    }
  }

  .right-section{
    position: relative;
    padding: 85px 0 180px;
    @include media('<=tablet'){
      padding: 80px 0;
    }
    .container {
      @include media('<=tablet'){
        display: flex;
        flex-direction: column;
      }
    }
    .container.bordered{
      max-width: 55%;
      margin-left: auto;
      margin-right: 0;
      padding-bottom: 100px;
      @include media('<=tablet'){
        max-width: 100%;
        order: 1;
        padding: 0;
      }
    }

    .img-left{
      position: absolute;
      right: calc(55% + 45px);
      bottom: 0;
      max-height: 100%;
      @include media('<=tablet'){
        width: calc(100% + 40px);
        position: relative;
        left: -20px;
        right: 0;
        order: 2;
      }
    }
    .reversed {
      @include media('<=tablet'){
        left: -20px;
        width: calc(100% - 45px);
        position: relative;
        &:before {
          left: auto;
          right: 0;
          transform: translate(100%, -50%);
        }
        &:after {
          left: auto;
          right: -6px;
          transform: translate(100%, -50%);
        }
      }
    }
  }

  .careers{
    margin-top: 120px;
    padding-bottom: 50px;
    position: relative;
    .container{
      padding-left: 10px;
      padding-bottom: 0;
      &:before {
        bottom: -30px;
      }
      &:after {
        bottom: -30px;
      }
    }
    .btn {
      margin: 0 auto 30px auto;
    }
    .mobile-hr {
      display: none;
      @include media('<=tablet'){
        display: block;
      }
    }
    .subtitle{
      padding-left: 15px;
      margin-bottom: 15px;
    }

    .items{
      position: relative;
      display: flex;
      justify-content: normal;
      padding: 60px 0 10px;
      margin-bottom: 60px;
      @include media('<=tablet'){
        display: block;
        margin-top: -80px;
        padding-top: 140px;
      }
      &:before{
        position: absolute;
        content: "";
        top: 0;
        bottom: -190px;
        @include fullWidth();
        background-color: #eeeef5;
        background-image: url('../../public/images/BG_company_careers.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        z-index: -1;
      }
    }

    .box-item{
      margin-right: 20px;
      @include media('<=tablet'){
        max-width: 250px;
        & + .box-item{
          margin-top: 20px;
        }
      }
    }
  }
}
.map-section {
  background: #eeeef5;
  padding-top: 60px;
  position: relative;
  .container.bordered {
    padding: 20px 0 120px 27px;
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 60px;
    background: $white;
  }
  p{
    max-width: 540px;
    margin: 25px 0 0;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.6;
    letter-spacing: 0.3px;
    color: $darkGray;
    display: inline-block;
    margin-right: 60px;
    @include media('<=tablet'){
      color: $darkGray;
      font-size: 16px;
      line-height: 1.38;
      margin: 35px 0 0;
      max-width: 90%;
    }
  }
  .btn{
    margin: 45px 0 0 0;
    display: inline-block;
    @include media('<=tablet'){
      display: none !important;
    }
  }
  .img-right{
    position: absolute;
    top: -135px;
    right: 0;
    max-width: 410px;
  }
  hr{
    width: calc(100% + 340px);
    @include media('<=tablet'){
      width: calc(100% - 45px);
    }
  }
  .map-block {
    margin-left: 180px;
    margin-top: 60px;
    width: 578px;
    height: 382px;
    // background-image: url('../../public/images/map.svg');
    background-size: 100% auto;
    background-repeat: no-repeat;
    position: relative;
    background-position: center center;
    @include media('<=tablet'){
      margin-left: 0;
    }
    @include media('<=880px'){
      width: 579px;
      height: 381px;
    }
    @include media('<=680px'){
      transform:scale(0.9);
    }
    @include media('<=580px'){
      transform:scale(0.8);
      margin-left: -5vw;
    }
    @include media('<=480px'){
      transform:scale(0.6);
      margin-left: -25vw;
    }
    @include media('<=380px'){
      transform:scale(0.5);
      margin-left: -35vw;
      margin-top: -50px;
      margin-bottom: -100px;
    }
    ul {
      padding: 0;
      margin: 0;
      width: 800px;
      height: 530px;
      list-style: none;
      position: static;
      li {
        position: absolute;
        i {
          z-index: 100;
          height: 6px;
          width: 6px;
          border-radius: 100%;
          background: transparent;
          display: block;
          transition:300ms;
          cursor: pointer;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            height: 40px;
            width: 40px;
            background: rgba(57, 170, 255, 0.2);
            border: 2px solid #0066c3;
            border-radius: 100%;
            top: 50%;
            left: 50%;
            transform:translate(-50%,-50%);
            opacity: 0;
            visibility: hidden;
            transition:300ms;
            pointer-events: none;
          }
          &:hover {
            &:before {
              opacity: 1;
              visibility: visible;
            }

            & + .inner-box {
              opacity: 1;
              visibility: visible;
            }
          }
        }
        .inner-box {
          z-index: 0;
          position: relative;
          min-height: 85px;
          width: 180px;
          background: $blue;
          left: -48%;
          top: 31px;
          display: block;
          padding: 20px;
          opacity: 0;
          visibility: hidden;
          transition:300ms;
          &:before {
            content: '';
            position: absolute;
            left: 50%;
            top: -15px;
            width: 2px;
            height: 15px;
            background: $blue;
          }
          h2, span {
            font-size: 14px;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.43;
            letter-spacing: 0.3px;
            font-weight: bold;
            color: $white;
            margin: 0;
            text-transform: uppercase;
          }
          span {
            font-weight: normal;
            text-transform: initial;
          }
        }
      }
    }
  }
  .map-block-image {
    svg {
      max-width: 100%;
      height: auto;
    }

    .is-active {
      fill: $blue;
    }
  }
}
::-moz-focus-inner {border:0;}                       /* firefox */
:focus {outline:none;}                               /* ie8, chrome, etc */
.filter-makers {
  position: relative;
  height: 80px;
  margin-top: 50px;
  h4 {
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: 0.3px;
    color: #ffffff;
    line-height: 80px;
    margin: 0;
    margin-right: 100px;
    display: inline-block;
  }
  .styled-select {
    height: 50px;
    width: 300px;
    display: inline-block;
    position: relative;
    @include media('<=tablet'){
      display: block;
    }
 }

 .styled-select select {
    background: transparent;
    border: none;
    width: 300px;
    height: 50px;
    appearance: none;
    color: $white;
    padding-top: 7px;
    &:active, &:focus, &:-moz-focus-inner {
      outline: 0 !important;
    }
    option {
      color: $darkGray;
    }
 }
 .styled-select.slate {
    height: 50px;
    width: 300px;
    background: $blue;
    border: 1px solid $white;
    z-index: 1;
    &:active, &:focus {
      outline: 0 !important;
    }
    &:after {
      content: '';
      right: 10px;
      top: 0;
      bottom: 0;
      z-index: -1;
      position: absolute;
      width: 30px;
      background: url(../../public/images/icons/arrow-white.svg);
      background-repeat: no-repeat;
      background-position: center right;
    }
 }
  &:before{
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    @include fullWidth();
    background-color: $blue;
    z-index: -1;
  }

  @include media('<=tablet'){
    height: auto;
    padding: 40px 0;
    h4 {
      display: block;
      margin-right: 0;
      margin-bottom: 20px;
      line-height: initial;
    }
  }
}
.center-button {
  margin: 45px auto !important;
}
.intro-text br {
  @include media('<=tablet'){
    display: none;
  }
}
