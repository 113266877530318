.article-header{
  position: relative;
  max-height: 600px;
  min-height: 600px;
  margin-bottom: 169px;
  @include media('<=tablet'){
    margin-bottom: 0;
  }
  .bg{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    width: 100%;
    height: 100%;
    @include media('<=tablet'){
      position: relative;
      top: 80px;
      left: 0;
      transform: translate(0,0)
    }
  }

  .container{
    max-height: 600px;
    min-height: 600px;
    @include media('<=tablet'){
      max-height: auto;
      min-height: auto;
      padding-left: 0;
      padding-right: 0;
      margin-top: -25px;
    }
  }

  .text-wrapper{
    position: absolute;
    background-color: $blue;
    padding: 40px 40px 0;
    bottom: -96px;
    width: 100%;
    @include media('<=tablet'){
      padding: 20px;
      position: relative;
    }
  }

  .text-wrapper-terms {
    overflow: hidden;
  }

  h1{
    font-size: 46px;
    font-weight: bold;
    line-height: 1.13;
    letter-spacing: 0.2px;
    text-align: center;
    color: $white;
    margin: 0 0 50px;
    padding: 0 20px;
    @include media('<=tablet'){
      font-size: 32px;
      text-align: left;
      padding: 0;
      margin-top: 30px;
    }
  }
  .socialPost {
    &__Share {
      @include media('<=tablet'){
        border-top: 1px solid $white;
        margin-top: 20px;
      }
    }
  }
  .description-social{
    display: flex;
    justify-content: space-between;
    padding: 35px 135px;
    border-top: 1px solid $white;
    @include media('<=tablet'){
      display: block;
      padding: 20px 0;
    }
    h5{
      display: flex;
      color: $white;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.3px;
      margin: 0;
      text-transform: uppercase;
      span{
        margin-left: 12px;
        padding-left: 8px;
        border-left: 1px solid $white;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      right: 0;
      margin-top: -10px;
      @include media('<=tablet'){
       margin-top: 20px;
       margin-bottom: -20px;
      }
    }
    li {
      display: inline-block;
      margin: 0 10px;
      text-align: center;
      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
        a {
          svg {
            bottom: 11px;
          }
        }
      }
    }
    a {
      transition:300ms;
      display: block;
      width: 45px;
      height: 45px;
      border: 1px solid $blue;
      position: relative;
      svg {
        display: inline-block;
        position: absolute;
        bottom: 12.5px;
        left: 50%;
        height: 20px;
        transform: translateX(-50%);
      }
      &:hover {
        border-color: #a1d7ff;
        svg {
          fill: #a1d7ff !important;
          path {
            fill: #a1d7ff !important;
          }
        }
      }
    }
  }
}

.article-container{
  max-width: 620px;
  margin: 0 auto;
  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 18px;
  }
  h5 {
    font-size: 16px;
  }
  @include media('<=tablet'){
    padding: 20px;
    p {
      font-size: 18px;
    }
  }
}

.article-container-blank {
  padding-top: 40px;
}

article.article{
  padding-bottom: 160px;
  p{
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: 0.3px;
    color: $darkGray;
    margin: 0;
    & + p, & + blockquote + p{
      margin-top: 25px;
    }
  }
  a{
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: 0.3px;
    color: $blue;
    margin: 0;
    transition:300ms;
    text-decoration: none;
    &:hover {
      color: $lightBlue;
    }
  }
  li{
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: 0.3px;
    color: $darkGray;
    margin: 0;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  blockquote{
    position: relative;
    font-size: 38px;
    font-weight: 600;
    line-height: 1.37;
    letter-spacing: 0.9px;
    color: $lightBlue;
    font-weight: 400;
    &.tl, &.tr{
      max-width: 390px;
      padding: 30px 20px;
      margin: 20px;
      @include media('<=tablet'){
        margin: 20px 0;
      }
    }

    &.tl{
      float: left;
      margin-left: -155px;
      &:after{
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        border: 5px solid #d7d7de;
        border-width: 5px 5px 5px 0;
        width: 55px;
      }
      @include media('<=tablet'){
        margin-left: 0;
        max-width: 100%;
        &:before{
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          bottom: 0;
          border: 5px solid #d7d7de;
          border-width: 5px 0 5px 5px;
          width: 55px;
        }
      }
    }

    &.tr{
      float: right;
      margin-right: -155px;
      &:before{
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        bottom: 0;
        border: 5px solid #d7d7de;
        border-width: 5px 0 5px 5px;
        width: 55px;
      }
      @include media('<=tablet'){
        margin-right: 0;
        max-width: 100%;
        &:after{
          position: absolute;
          content: "";
          top: 0;
          right: 0;
          bottom: 0;
          border: 5px solid #d7d7de;
          border-width: 5px 5px 5px 0;
          width: 55px;
        }
      }
    }
  }
}
.remove-height {
  min-height: initial;
  margin: 150px 0 50px 0;
  .container {
    min-height: initial;
  }
  .text-wrapper {
    position: relative;
    bottom: 0;
  }
}