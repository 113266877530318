.home-header{
  @include media('<=tablet'){
    margin-top: 79px;
  }
  .home-carousel{
    @include media('<=tablet'){
      min-height: 405px;
    }
  }
  .slide{
    position: relative;
    display: flex;
    justify-content: space-between;
    transition: 900ms !important;
    @include media('<=tablet'){
      font-size: 32px;
      min-height: 405px;
    }
    &::first-of-type {
      h1 {
        span {
          transform: translate(0,20%);
        }
      }
    }
  }

  .img-wrapper{
    width: calc(50% - 11px);
    img{
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  h1{
    font-size: 46px;
    font-weight: 400;
    line-height: 1.13;
    letter-spacing: 0.3px;
    color: $white;
    margin: 0;
    overflow: hidden;
    @include media('<=tablet'){
      font-size: 32px;
    }
    span{
      display: block;
      position: relative;
      transition: 800ms;
      transform: translate(-20%,0);
    }
  }
  .slick-active {
    h1 {
      span {
        transform:translate(0,0);
      }
    }
  }
  .text-wrapper{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $lightBlue;
    width: 100%;
    max-width: 380px;
    padding: 30px 30px 135px;

    @include media('<=tablet'){
      padding: 25px 30px 80px;
    }
    @include media('<=650px'){
      max-width: calc(100% - 140px)
    }
    @include media('<=420px'){
      max-width: calc(100% - 40px);
    }

    &:before{
      position: absolute;
      content: "";
      left: -70px;
      right: -70px;
      bottom: 100px;
      height: 3px;
      background-color: $yellow;
      z-index: 10;
      @include media('<=tablet'){
        bottom: 60px;
      }
      @include media('<=650px'){
        left: -50px;
        right: -50px;
      }
    }
    &:after {
      content: '';
      top: 0;
      left: 0;
      bottom: 0;
      width: 30px;
      background-color: $lightBlue;
      position: absolute;
      z-index: 1;
    }
  }

  .circle{
    position: absolute;
    bottom: 100px;
    @include media('<=tablet'){
      bottom: 60px;
    }
  }

  .circle-filled{
    right: -70px;
    transform: translate3d(100%, 50%, 0);
    @include media('<=650px'){
      right: -50px;
    }
  }

  .circle-empty{
    left: -70px;
    transform: translate3d(-100%, 50%, 0);
    @include media('<=650px'){
      left: -50px;
    }
  }
}

.homepage{
  .under-header-links{
    margin: 30px 0 0;
    @include media('<=tablet'){
      margin: 0;
    }
    .container{
      display: flex;
      justify-content: space-between;
      @include media('<=tablet'){
        display: block;
      }
    }

    a{
      position: relative;
      display: block;
      text-decoration: none;
      width: calc(50% - 15px);
      padding: 32px 35px;
      border: 1px solid $lightGray;
      z-index: 1;
      @include media('<=tablet'){
        width: 100%;
        margin-top: 20px;
        padding: 15px 25px;
      }

      &:before, &:after{
        position: absolute;
        content: "";
        top: -1px;
        bottom: -1px;
        width: 55px;
        border-style: solid;
      }

      &:before{
        left: -1px;
        border-width: 5px 0 5px 5px;
      }

      &:after{
        right: -1px;
        border-width: 5px 5px 5px 0;
      }

      &:first-of-type{
        &:before, &:after{
          border-color: $lightBlue;
        }

        h2:before{
          background-color: $lightBlue;
          transform-origin: 0 0;
        }
      }

      &:last-of-type{
        &:before, &:after{
          border-color: $blue;
        }

        h2:before{
          background-color: $blue;
          transform-origin: 100% 0;
        }
      }

      &:hover{
        h2{
          color: $white;
          &:before{
            transform: scaleX(1);
          }
        }
      }
    }

    h2{
      font-size: 46px;
      font-weight: bold;
      line-height: 1.13;
      letter-spacing: 0.3px;
      color: #6b6b6f;
      margin: 0;
      transition: color 200ms;
      backface-visibility: hidden;
      @include media('<=tablet'){
        font-size: 32px;
        text-align: center;
        letter-spacing: 0.2px;
      }
      &:before{
        position: absolute;
        content: "";
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        z-index: -1;
        transform: scaleX(0);
        transition: transform 200ms;
        backface-visibility: hidden;
      }

      span{
        display: block;
        font-size: 20px;
        line-height: 1.4;
        letter-spacing: 0.3px;
        margin-bottom: -2px;
        @include media('<=tablet'){
          font-size: 18px;
        }
      }
    }
  }

  .services{
    position: relative;
    overflow: hidden;
    @include media('<=tablet'){
      overflow: visible;
    }
    &:before{
      position: absolute;
      content: "";
      top: 70px;
      left: 0;
      right: 0;
      min-height: 300px;
      background-color: #eeeef5;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      @include media('<=tablet'){
        min-height: auto;
        top: 50px;
        bottom: -70px;
        z-index: -2;
      }
      @include media('<=mobile'){
        top: 70px;
      }
    }

    h2{
      padding-left: 15px;
      @include media('<=tablet'){
        padding-left: 120px;
        margin: 0 0 40px;
      }
      @include media('<=mobile'){
        padding-left: 60px;
        margin-bottom: 60px;
      }
    }

    .container{
      padding: 10px 0;
      @include media('<=tablet'){
        padding: 0 20px;
      }
      &:before{
        position: absolute;
        content: "";
        top: 113px;
        left: -50px;
        width: 100vw;
        height: 2px;
        z-index: 2;
        @include media('<=tablet'){
          top: 0;
          left: 81px !important;
          bottom: -40px;
          width: 2px;
          height: auto;
          z-index: -1;
          @include media('<=mobile'){
            left: 21px !important;
          }
        }
      }

      &:after{
        position: absolute;
        content: "";
        top: 113px;
        left: -50px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        border-width: 8px;
        border-style: solid;
        transform: translate(-100%, -50%);
        @include media('<=tablet'){
          top: 0;
          left: 69.5px !important;
          transform: none;
          background-color: $white;
          @include media('<=mobile'){
            left: 9.5px !important;
          }
        }
      }
    }

    .items{
      display: flex;
      justify-content: space-between;
      padding-top: 90px;
      padding-bottom: 85px;
      @include media('<=tablet'){
        display: block;
        padding: 0 0 0 100px;
      }

      @include media('<=mobile'){
        padding-left: 40px;
      }

      &:before{
        position: absolute;
        content: "";
        top: 0;
        left: -10px;
        bottom: 0;
        width: 330px;
        border: solid #c5c5d2;
        border-width: 5px 0 0 5px;
      }

      &:after{
        position: absolute;
        content: "";
        left: -10px;
        bottom: 0;
        width: 150px;
        border-bottom: 5px solid #c5c5d2;
      }

      &:before, &:after{
        @include media('<=tablet'){
          display: none;
        }
      }

      .box-item{
        margin-bottom: -85px;
        z-index: 3;
        @include media('<=tablet'){
          margin-bottom: 20px;
        }
        &:before{
          position: absolute;
          content: "";
          top: -30px;
          left: calc(50% - 1px);
          width: 2px;
          height: 31px;
          @include media('<=tablet'){
            top: 50%;
            left: -50px;
            transform: translateY(-50%);
            width: 50px;
            height: 2px;
          }
        }

        &:after{
          position: absolute;
          content: "";
          top: -50px;
          left: 50%;
          transform: translateX(-50%);
          width: 20px;
          height: 20px;
          border-width: 2px;
          border-style: solid;
          background-image: url('../../public/images/homepage/corporations.png');
          background-position: -100vw 300px;
          background-size: 300px;
          background-color: #eeeef5;
          border-radius: 50%;
          @include media('<=tablet'){
            top: 50%;
            left: -50px;
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  @mixin serviceColor($color){
    .container{
      &:before{
        background-color: $color;
      }

      &:after{
        border-color: $color;
      }
    }
    .box-item{
      &:before{
        background-color: $color;
      }

      &:after{
        border-color: $color;
      }
    }
  }

  .corporations{
    margin-top: 100px;
    @include serviceColor($blue);
    @include media('<=tablet'){
      margin-top: 85px;
    }

    &:before{
      background-image: url('../../public/images/BG_landing_for_corporations.jpg');
    }
  }

  .startups{
    margin: 90px 0 80px;
    @include serviceColor($lightBlue);
    .container {
      &:before {
        left: auto;
        right: 160px;
      }
      &:after {
        left: auto;
        right: 115px;
      }
    }
    .items {
      justify-content: normal;
      .box-item {
        margin-right: 20px;
      }
    }
    &:before{
      background-image: url('../../public/images/BG_landing_for_startups.jpg');
    }
  }
}
.map-section-home {
  padding-bottom: 60px;
  &:before {
    bottom: auto;
    top: 0;
    height: 200px;
  }
  .map-block {
    width: 580px;
    height: 384px;
    margin-top: 160px;
    margin-left: 0;
    ul {
      li {
        i {
          width: 7px;
          height: 7px;
        }
      }
      /*li.point-1 {
        left: 271px !important;
        top: 142px !important;
      }
      li.point-2 {
        left: 302px !important;
        top: 142px !important;
      }
      li.point-3 {
        left: 344px !important;
        top: 173px !important;
      }
      li.point-4 {
        left: 261px !important;
        top: 173px !important;
      }
      li.point-5 {
        left: 73px !important;
        top: 173px !important;
      }
      li.point-6 {
        left: 291px !important;
        top: 163px !important;
      }
      li.point-7 {
        left: 365px !important;
        top: 142px !important;
      }
      li.point-8 {
        left: 291px !important;
        top: 142px !important;
      }
      li.point-9 {
        left:  281px !important;
        top:  151px !important;
      }
      li.point-10 {
        left:  312px !important;
        top:  163px !important;
      }
      li.point-11 {
        left:  302px !important;
        top:  120px !important;
      }
      li.point-12 {
        left:  291px !important;
        top:  151px !important;
      }
      li.point-13 {
        left:  271px !important;
        top:  151px !important;
      }
      li.point-14 {
        left:  281px !important;
        top:  142px !important;
      }
      li.point-15 {
        left:  323px !important;
        top:  112px !important;
      }
      li.point-16 {
        left:  323px !important;
        top:  163px !important;
      }
      li.point-17 {
        left:  302px !important;
        top:  163px !important;
      }
      li.point-18 {
        left:  312px !important;
        top:  142px !important;
      }
      li.point-19 {
        left:  312px !important;
        top:  182px !important;
      }
      li.point-20 {
        left:  469px !important;
        top:  246px !important;
      }
      li.point-21 {
        left:  375px !important;
        top:  205px !important;
      }
      li.point-22 {
        left:  480px !important;
        top:  205px !important;
      }
      li.point-23 {
        left:  156px !important;
        top:  142px !important;
      }
      li.point-24 {
        left:  323px !important;
        top:  195px !important;
      }
      li.point-25 {
        left:  291px !important;
        top:  131px !important;
      }
      li.point-26 {
        left:  302px !important;
        top:  151px !important;
      }
      li.point-27 {
        top: 173px !important;
        left: 291px !important;
      }
      li.point-28 {
        top: 151px !important;
        left: 281px !important;
      }*/
    }
    @include media('<=tablet'){
      display: none;
    }
  }
  @include media('<=tablet'){
    padding-top: 0;

    &:before {
      height: 160px;
    }
  }
}
.region-filter {
  margin-top: 50px;
  margin-bottom: -85px;
  .region-item {
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    color: #a6a6ad;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
    margin-bottom: 0;
    height: 50px;
    transition:300ms;
    cursor: pointer;
    input {
      display: inline-block;
      width: auto;
      opacity: 0;
    }
    &:before {
      content: '';
      position: absolute;
      left: 20px;
      right: -2px;
      bottom: -12.5px;
      height: 3px;
      background: $lightBlue;
      opacity: 0;
      transition:300ms;
    }
    &:hover {
      color: $lightBlue;
      &:before {
        opacity: 1;
      }
    }
    @include media('<=tablet'){
      margin-right: 10px;
      font-size: 14px;
      input {
        display: none;
      }
    }
  }
  .region-item.active {
    color: $lightBlue;
    &:before {
      opacity: 1;
    }
  }

  @include media('<=tablet'){
    white-space: nowrap;
    overflow-x: auto;
    margin-bottom: -140px;
  }
}
.people-block-wrapper {
  overflow-y: auto;
  max-height: 100%;
  max-height: calc(100% - 30px);
  z-index: 0;
  position: relative;
  @include scrollbars(8px, #b7b7c5, #dadae4);
  margin: 15px 0;

}
.peopleBlockButton {
  display: none !important;
  &--active {
    display: none !important;
    @include media('<=tablet'){
      display: block !important;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.peopleBlockWrapperMobile {
  &--active {
    @include media('<=tablet'){
      max-height: 440px;
      overflow: hidden;
    }
  }
}
.people-block {
  position: absolute;
  top: 200px;
  width: 300px;
  right: 0;
  bottom: 0;
  @include media('<=tablet'){
    width: 100%;
  }
  hr {
    z-index: 1;
    left: 50px;
    width: 100vw;
    border-color: #39aaff;
    position: absolute;
    border-style: solid;
    border-width: 0 0 2px;
    margin: 0;
    overflow: visible;
    @include pseudoCircle();

    &:before, &:after{
      top: 1px;
      transform: translate(-100%, -50%)
    }
    &:before{
      left: 0;
      border-color: #39aaff;
    }
    &:after{
      left: -6px;
      background-color: #39aaff;
    }
  }
  .team-item {
    max-width: initial;
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
    &:nth-child(n+5) {
      display: flex;
    }
    &:hover {
      h4, h5, h6{
        color: $darkGray;
      }
    }
    .person {
      width: 80px;
      height: 80px;
      margin: 0;
      border-color: #39aaff;
      .img-wrapper {
        top: 5px;
        right: 5px;
        bottom: 5px;
        left: 5px;
      }
    }
    .description {
      padding: 0 0 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h4 {
        text-align: left;
        font-weight: normal;
      }
      h5 {
        text-align: left;
        text-transform: uppercase;
        margin-top: 5px;
      }
    }
  }
  .hidden {
    display: none !important;
  }
  @include media('<=tablet'){
    position: relative;
    padding-bottom: 90px;
  }
}
