footer{
  background-color: $darkBlue;
  padding: 55px 0;
  .item, .flex-2{
    a{
      text-decoration: none;
    }
    
    ul, p, a{
      font-size: 14px;
      line-height: 1.57;
      letter-spacing: 0.3px;
    }
    
    ul{
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      font-weight: 300;
      list-style: none;
      a{
        color: #d8d8d8;   
        transition: color 200ms;
        transform: translateZ(0);
        &:hover{
          color: $lightBlue;
        }     
      }
    }
    
    p{
      margin: 0;
      color: #d8d8d8;
      font-weight: 300;

      a{
        color: #d8d8d8;
        &:hover{
          color: $lightBlue;
        }
      }
    }
    
    & > a{
      color: $white;
      font-weight: bold;
      &:hover{
        text-decoration: underline;
      }
      & + *{
        margin-top: 25px;
      }
    }
  }
  
  .item{
    flex: 1;
    @include media('<=tablet'){
      display: none;
    }
  }
  
  .flex-2{
    flex: 2;
  }
  
  .footer-navigation{
    display: flex;
  }
  
  .disclaimer{
    text-align: center;
    margin-top: 25px;
    border-top: 1px solid #497499;
    p, a{
      color: $white;
      font-size: 12px;
      font-weight: 300;
      line-height: 1.83;
      letter-spacing: 0.3px;
    }
    
    p{
      margin: 25px 0 0;
      &:first-child{
        @include media('<=tablet'){
          text-align: left;
        }
      }
    }
    
    a{
      transform: translateZ(0);
      transition: color 200ms;
      padding: 0 5px;
      &:hover{
        color: $lightBlue;
      }
    }
  }
}
.social-networks {
  width: 90%;
  padding: 0 0 0 0;
  margin: 15px 0 0 0;
  ul {
    list-style: none;
    display: block !important;
  }
  li {
    display: inline-block;
  }
  a {
    display: block;
    margin: 0 10px;
    height: 20px;
    width: 20px;
  }
  img {
    vertical-align: middle;
  }
}