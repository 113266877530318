*{
  box-sizing: border-box;
  font-family: 'Titillium Web', sans-serif;
}

body, html{
  margin: 0;
  padding: 0;
}

.site-wrapper {
  overflow: hidden;
}

.container{
  max-width: 940px;
  margin: 0 auto;
  @include media('<=tablet'){
    padding: 0 20px;
  }

  &.bordered{
    position: relative;
    padding: 20px 0 30px 27px;
    @include media('<=tablet'){
      padding: 0 20px;
    }

    &:before{
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      width: 330px;
      border: solid #d7d7de;
      border-width: 5px 0 0 5px;
    }

    &:after{
      position: absolute;
      content: "";
      left: 0;
      bottom: 0;
      border: solid #d7d7de;
      border-width: 0 0 5px 0;
      width: 150px;
    }

    .subtitle{
      margin-top: 0;
    }

    &:before, &:after{
      @include media('<=tablet'){
        display: none;
      }
    }
  }
}

.bold{
  font-weight: bold;
}

.btn{
  position: relative;
  display: block;
  width: 100%;
  max-width: 300px;
  padding: 0;
  border: 2px solid;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 1;
  background-color: transparent;
  transition: background-color 200ms, color 200ms;
  backface-visibility: hidden;
  padding: 12px 25px 12px;
  text-decoration: none;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;
  @include media('<=tablet'){
    font-size: 20px;
  }
  &.btn-medium{
    max-width: 200px;
  }

  &:before{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
    backface-visibility: hidden;
    z-index: -1;
    transition: transform 200ms;
  }

  &:hover{
    &:before{
      transform: translateY(0);
    }
  }
}

.btn-white{
  color: $white;
  border-color: $white;
  &:before{
    background-color: $white;
  }
  &:hover{
    color: $blue;
  }
}

.btn-blue{
  color: $blue;
  border-color: $blue;
  &:before{
    background-color: $blue;
  }
  &:hover{
    color: $white;
  }
}

.circle{
  display: block;
  font-size: 0;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  @include media('<=tablet'){
    width: 32px;
    height: 32px;
  }
}

.circle-filled{
  border: 17px solid $yellow;
  @include media('<=tablet'){
    border-width: 10px;
  }
}

.circle-empty{
  position: relative;
  border: 3px solid $yellow;
  &:before{
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $yellow;
    transform: translate(-50%, -50%);
    @include media('<=tablet'){
      width: 11px;
      height: 11px;
    }
  }
}

@mixin scrollbars($size: 8px, $foreground-color: darken($white, 20%), $background-color: darken($white, 10%)) {
  &::-webkit-scrollbar {
    width:  $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
    border-radius: 10px;
  }
}

@mixin setSubtitleColor($color){
  color: $color;
  & + hr{
    border-color: $color;
    &:before{
      border-color: $color;
    }

    &:after{
      background-color: $color;
    }
  }
}

.subtitle{
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.4px;
  margin: 10px 0;
  @include media('<=tablet'){
    font-size: 22px;
  }
  &.subtitle-blue{
    @include setSubtitleColor($blue);
  }

  &.subtitle-dark-blue{
    @include setSubtitleColor($darkBlue);
  }

  &.subtitle-light-blue{
    @include setSubtitleColor($lightBlue);
  }

  & + hr{
    position: absolute;
    left: -100%;
    width: calc(100% + 340px);
    border-style: solid;
    border-width: 0 0 2px;
    margin: 0;
    overflow: visible;
    @include media('<=tablet'){
      margin-top: 10px;
      left: 0;
      width: calc(100% - 45px);
    }

    @include pseudoCircle();

    &:before, &:after{
      top: 0;
      transform: translate(100%, -50%)
    }

    &:before{
      right: 0;
    }

    &:after{
      right: -6px;
    }

    &.reversed{
      left: -170px;
      width: 100vw;
      &:before, &:after{
        transform: translate(-100%, -50%)
      }

      &:before{
        left: 0;
      }

      &:after{
        left: -6px;
      }
    }
  }
}

.container{
  position: relative;
}

.small-circle{
  font-size: 0;
  @include pseudoCircle();
  display: block;
  width: 18px;
  height: 18px;
  &:before, &:after{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.top-space {
  margin-top: 100px;
  @include media('<=tablet'){
    margin-top: 0;
  }
}
img.alignright {
  float:right;
  margin:0 0 1em 1em;
}
img.alignleft {
  float:left;
  margin:0 1em 1em 0;
}
img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.alignright {
  float:right;
}
.alignleft {
  float:left;
}
.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 750px) {
  .wmpci-popup-body {
    width: 100% !important;
    max-width: 90% !important;
    .form-control {
      width: 100% !important;
      max-width: 77% !important;
      margin-bottom: 20px !important;
    }
  }
}
